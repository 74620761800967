

export const searchPreviewInitialState = {
    audioVisual: {
        status: 'idle',
        results: {
            
            }
    },
    panelIsVisible: false
}


export const searchPreviewReducer = (state = searchPreviewInitialState, action) => {
    switch (action.type) {
        case 'PREVIEW_AUDIOVISUAL_SET_FETCH_STATUS_ACTION':
        return {
                ...state,
                audioVisual: {
                    ...state.audioVisual,
                    status: action.payload
                }
            }
        case 'PREVIEW_AUDIOVISUAL_SET_FETCH_RESULTS_ACTION':
            return {
                    ...state,
                    audioVisual: {
                            ...state.audioVisual,
                            results: action.payload
                        }
            }
        case 'SET_PANEL_VISIBILITY':
            return {
                ...state,
                panelIsVisible: action.payload
            }
        default:
            return state
    }
}