export const setAudiovisualFetchStatusAction = (status) => {
    return { type: 'AUDIOVISUAL_SET_FETCH_STATUS_ACTION', payload: status }
}

export const setAudiovisualFetchResultsAction = (results) => {
    return { type: 'AUDIOVISUAL_SET_FETCH_RESULTS_ACTION', payload: results }
}

export const setAudiovisualCurrentPageAction = (currentPage) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_CURRENT_PAGE', payload: currentPage }
}

export const setAudiovisualTotalPagesAction = (totalPages) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_TOTAL_PAGES', payload: totalPages }
}

export const setAudiovisualRowsAction = (rowCount) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_ROWS', payload: rowCount }
}

export const setAudiovisualFirstAction = (first) => {
    return { type: 'AUDIOVISUAL_SET_PAGINATION_FIRST', payload: first }
}

export const setAudiovisualFilterAction = (filter) => {
    return { type: 'AUDIOVISUAL_SET_FILTER', payload: filter }
}

export const setAudiovisualSortAction = (sort) => {
    return { type: 'AUDIOVISUAL_SET_SORT', payload: sort }
}

export const setAudiovisualExcelExportAction = () => {
    return { type: 'AUDIOVISUAL_SET_EXPORT_EXCEL' }
}



