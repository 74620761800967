import { useState, useRef, useEffect } from 'react'
import { useRefAsState } from 'modules/React.Extensions'
import { useSelector, useDispatch } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'
import { transformDraftToProgrammeInfo, transformEpisodeDraftToEpisodeInfo } from 'modules/data-entry/AudioVisual/dataTransforms'

const createDataByEndpoint = async (endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, controller,setNewAggregate, ) => {
    let responseCode = -1
    try {

        const transformedBody = transformDraftToProgrammeInfo(body, original, episodeOriginal, episodeDraft, true);
        const response = await authFetch(endpoint, {
            body: transformedBody ? JSON.stringify(transformedBody) : undefined,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            signal: controller.signal
        })

        const wasAborted = controller?.signal?.aborted

        controller = null

        if (wasAborted) {
            return null
        }
        responseCode = response.status
        

        if (responseCode === 201) {            
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode }
        }

        throw {
            Message: 'The AV data create was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    }
    catch (error) {
        throw {
            Message: 'The AV data create was not successful',
            StatusCode: responseCode,
            Details: error
        }
    }
}
const createEpisodeDataByEndpoint = async (endpoint, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate) => {
    let responseCode = -1

    try {
        const transformedBody = transformEpisodeDraftToEpisodeInfo(episodeOriginal, episodeDraft);

        const response = await authFetch(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        const wasAborted = controller?.signal?.aborted

        controller = null

        if (wasAborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 201) {
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode } // Return response code and new aggregate version        
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const setUpCreate =  (endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate) => {

    const controller = new AbortController()
    return {
        controller,
        fetch: () => createDataByEndpoint(endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate)
    }
}

const setUpEpisodeCreate =  (endpoint, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate) => {

    const controller = new AbortController()
    return {
        controller,
        fetch: () => createEpisodeDataByEndpoint(endpoint, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate, )
    }
}

// ReSharper disable once InconsistentNaming
const DataCreate = (props) => {

    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const setSapCodeAction = props.setSapCodeAction
    const requestSelector = props.requestSelector
    const setNewProgrammeStatus = props.setNewProgrammeStatus
    const setAggregate = props.setAggregateAction
    const episodeOnly = props.episodeOnly

    /* Dispatch */
    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setSapCode = (result) => dispatch(setSapCodeAction(result))
    const setNewProgrammeToFalse = () => dispatch(setNewProgrammeStatus(false))
    const setNewAggregate = (newAggregate) => dispatch(setAggregate(newAggregate))

    /* Selectors */
    const dataCreateRequest = useSelector(requestSelector)
    const { endpoint, status, body, original, episodeDraft, episodeOriginal, aggregateVersion } = dataCreateRequest

    const onComplete = props.onComplete


    //Only perform fetch once when the component renders for the first time.
    //TODO: fix the infinite render loop that is occurring if useEffect is removed
    const handleCreate = async () => {
        if (episodeOnly) {
            setStatus({ updateStatus: 'pending' })
        }
        else {
            setStatus({ status: 'pending' })
        }

        if (status === 'pending' && abortController) {
            abortController.abort()
        }



        const { controller, fetch } = episodeOnly ? setUpEpisodeCreate(endpoint, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate)
            : setUpCreate(endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate)
        setAbortController(controller)
        try {
            const result = await fetch()
            if (result) {
                if (episodeOnly) {
                    setStatus({ updateStatus: 'complete' });
                }
                else {  
                    setSapCode(result)
                    setStatus({ status: 'complete' });                   
                }              
                if (onComplete) {
                    setTimeout(() => {
                        onComplete()
                        setNewProgrammeToFalse()
                    }, 5000);
                }
            }
            
        }

        catch (error) {
            if (!episodeOnly) {
                setSapCode({})
            }
            setStatus({ status: 'error', error: error })
        }


    }


    if (status === 'idle') {
        handleCreate()
    }
    return null
}

export default DataCreate