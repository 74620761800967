
const initialState = null

export const assetReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case '_assets_Set':
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }

}
