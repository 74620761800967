import { useState, useRef } from 'react';
import { useRefAsState } from 'modules/React.Extensions'
import { useDispatch, useSelector } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'
import { transformDraftToProgrammeInfo, transformEpisodeDraftToEpisodeInfo } from 'modules/data-entry/AudioVisual/dataTransforms';

const updateDataByEndpoint = async (endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate) => {
    let responseCode = -1

    try {
        const transformedBody = transformDraftToProgrammeInfo(body, original, episodeOriginal, episodeDraft,false);

        const response = await authFetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        if (controller.signal.aborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 200) {            
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode } // Return response code and new aggregate version
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const updateEpisodeDataByEndpoint = async (endpoint, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate) => {
    let responseCode = -1

    try {
        const transformedBody = transformEpisodeDraftToEpisodeInfo(episodeOriginal, episodeDraft);

        const response = await authFetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        if (controller.signal.aborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 200) {
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode } // Return response code and new aggregate version
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const setUpUpdate = (endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate) => {
    const controller = new AbortController()
    return {
        controller,
        update: () => updateDataByEndpoint(endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate),
    }
}

const setUpEpisodeUpdate = (endpoint, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate) => {
    const controller = new AbortController()
    return {
        controller,
        update: () => updateEpisodeDataByEndpoint(endpoint, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate),
    }
}

// ReSharper disable once InconsistentNaming
const DataUpdate = (props) => {
    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const requestSelector = props.requestSelector
    const onComplete = props.onComplete
    const setAggregate = props.setAggregateAction
    const episodeOnly = props.episodeOnly

    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setNewAggregate = (newAggregate) => dispatch(setAggregate(newAggregate))

    const dataUpdateRequest = useSelector(requestSelector)
    const { endpoint, status, body, original, episodeDraft, episodeOriginal, aggregateVersion } = dataUpdateRequest

    const handleUpdate = async () => {
        if (episodeOnly) {
            setStatus({ updateStatus: 'pending' })
        }
        else {
            setStatus({ status: 'pending' })
        }
        const { controller, update } = episodeOnly
            ? setUpEpisodeUpdate(endpoint, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate)
            : setUpUpdate(endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate);

        setAbortController(controller);

        try {
            const result = await update()
            if (result) {
                if (episodeOnly) {
                    setStatus({ updateStatus: 'complete' });
                }
                else {
                    setStatus({ status: 'complete' });
                }
                if (onComplete) {
                    setTimeout(() => {
                        onComplete()
                    }, 5000);
                }
            }
        } catch (error) {
            setStatus({ status: 'error', error: error })
        }
    }

    if (status === 'idle') {
        handleUpdate()
    }

    return null
}

export default DataUpdate
