// Searches can have different modes. The mode defines where (universal) search results
// are focused. Imagine the results filling several tabs in a results page. This value defines
// which tab has focus by default.

// I've deliberately made these all singular, just for consistency. It doesn't matter
// if you change it, but please be consistent across all values.
export const searchMode = Object.freeze({
    Default: 0,
    AudioVisual: 1,
    Membership: 2,
    PublishedWork: 3,
    Finance: 4,
    Contact: 5,         // Here only. Not reqd to be synced
    Serial: 6,          // Here only. Not reqd to be synced
    VisualArt: 7      // Here only. Not reqd to be synced
});