const signalR = require('@microsoft/signalr')


export const createConnection = (url, callbacks) => {

    const connection = new signalR.HubConnectionBuilder()
        .withServerTimeout(30*1000)
        .withKeepAliveInterval(5*1000)
        .withAutomaticReconnect()
        .withUrl(url)
        .build()

    for (let prop in callbacks) {
        if (callbacks.hasOwnProperty(prop)) {
            connection.on(prop, callbacks[prop])
        }
    }
    return connection
}
