import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner'
import { setTvFilmRadioFetchStatusAction, setTvFilmRadioFetchResultsAction, setSearchResultsTabSelect, setPanelVisibilityAction } from './store/actions'
import { selectTvFilmRadioFetchRequest, selectTvFilmRadioFetchResults, selectAvTabLink, selectAvFetchIsPending } from './store/selectors'
import { searchResultsTab } from 'modules/enums'
import { avTemplate } from 'components/common/PreviewTemplate'
import FullTextSearch from 'api/FullTextSearch'
import SearchResultsTemplate from 'components/common/SearchResultsTemplate'


//// ReSharper disable once InconsistentNaming
function AudioVisualListPreview(props) {
    const dispatch = useDispatch()
    const setTab = () => dispatch(setSearchResultsTabSelect(searchResultsTab.AudioVisual))
    const setPanelIsVisibletoFalse = () => dispatch(setPanelVisibilityAction(false))
    const resultIsPending = useSelector(selectAvFetchIsPending)
    const selectors = {
        fetchResults: selectTvFilmRadioFetchResults,
        tabLink: selectAvTabLink
    }

    const commonElements = {
        searchComponent: <FullTextSearch
            id={props.id + '_avList_FullTextSearch'}
            setStatusAction={setTvFilmRadioFetchStatusAction}
            setResultsAction={setTvFilmRadioFetchResultsAction}
            requestSelector={selectTvFilmRadioFetchRequest}
        />,
        title: 'AV & Radio',
        template: avTemplate,
        setTab: setTab,
        setPanelIsVisibletoFalse: setPanelIsVisibletoFalse,
        searchTerm: props.searchTerm,
        viewAllIcon: 'fa-light fa-clapperboard-play',
        height: props.height,
        width: props.width
    }

    
    
    if (resultIsPending) {
        return (
            <div>
                <br /><br /><br /><br />
                <ProgressSpinner
                    id={props.id + '_avSpinner'}
                    style={{
                        width: '350px',
                        height: '50px'
                    }}
                    strokeWidth="8"
                    fill="var(--surface-ground)" 
                    animationDuration=".5s" />
            </div>
        )
    }
    else {

        return (
            <SearchResultsTemplate
            id={props.id + '_avList'}
            selectors={selectors}
            commonElements={commonElements} />
        )
    }
}
    export default AudioVisualListPreview