import { combineReducers } from '@reduxjs/toolkit';

const initialState = {
    breadCrumbStack: {
        items: []
    }
}

const breadCrumbItemReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SetBreadCrumbStack':
            return {
                ...state,
                breadCrumbStack: {
                    items: [...action.payload]
                }
            }
        case 'PushBreadcrumbItemToStack':
            {
                const items = state.breadCrumbStack.items
                items.push(action.payload)
                return {
                ...state,
                breadCrumbStack: {
                    items: items
                }
            }
    }
        default:
            return state
    }
}

export const secondaryNavigationReducer = combineReducers({
    breadCrumb: breadCrumbItemReducer    
})