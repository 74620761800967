import { React } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setSearchResultsTabSelect } from 'components/Body/Search/store/actions'
import { searchResultsTab } from 'modules/enums'
import { serialTemplate } from 'components/common/PreviewTemplate'
import { selectSerialsTabLink } from './store/selectors'
import { ScrollPanel } from 'primereact/scrollpanel'

// ReSharper disable once InconsistentNaming
function SerialListPreview(props) {
    /* Dispatch */
    const dispatch = useDispatch()
    const setSerialsTab = () => dispatch(setSearchResultsTabSelect(searchResultsTab.Serials))


    /* Selectors */
    const serialsTabLink = useSelector(selectSerialsTabLink)

    let serialResultList = []
    let serialSearchResultsCount = 0

    return (
        <div class="alcs-inner-panel medium-grey">
            <div class="header"><span class="title">Serials</span></div>
            <div class="content">
                <ScrollPanel id={props.id + '_scrollPanel'} style={{ width: props.width, height: props.height }}>
                    <div id={props.id + '_serialList'}>
                        <b id={props.id + '_serialList_title'}>Serials
                            <button id={props.id + '_serialList_title_button'}>{serialSearchResultsCount} results</button>
                        </b>
                        <br />
                        <div id={props.id + '_serialList_tableContainer'} className={'table-responsive'}>
                            {serialResultList && serialResultList.length > 0 ? (
                                <table id={props.id + '_serialList_tableContainer_table'} class="table">
                                    <thead id={props.id + '_serialList_tableContainer_table_head'}>
                                        {/* <!-- Table header goes here -->*/}
                                    </thead>
                                    <tbody id={props.id + '_serialList_tableContainer_table_body'}>
                                        {/*<!-- Use a loop to iterate through serialResultList and generate rows with serialTemplate -->*/}
                                        {serialResultList.map((item, index) => (
                                            <tr id={props.id + '_serialList_tableContainer_table_body_row_' + index}>
                                                {/* <!-- Render data using serialTemplate -->*/}
                                                {serialTemplate(item, props.id + '_serialList_tableContainer_table_body_row_' + index)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p id={props.id + '_serialList_tableContainer_noResultMessage'}>Future Implementation</p>
                            )}
                        </div>
                        <div id={props.id + '_serialList_viewAllContainer'}>
                            <Link id={props.id + '_serialList_viewAllContainer_link'} to={serialsTabLink} onClick={setSerialsTab}>
                                <span id={props.id + '_serialList_viewAllContainer_link_text'}>View All Serial Results</span>
                            </Link>
                        </div>
                    </div>
                </ScrollPanel>
            </div>
        </div>

    )
}
export default SerialListPreview