import React from 'react';

import { useParams } from 'react-router-dom';

export function ArticleDetails(props) {
    // This component now has a props.id and a props.className

    const params = useParams()
    const id = params.id || '*new*'

    return (
        <p>
        Article Reference: {id}
    </p>
)
}
