import React from 'react'
import { useSelector } from 'react-redux'
import { selectSignalRIsConnected } from './store'


// ReSharper disable once InconsistentNaming
const SignalRIndicator = (props) => {

    const signalRIsConnected =  useSelector(selectSignalRIsConnected)

    const iconStyle = signalRIsConnected ? { color: 'green' } : { color: 'red' }

    return <span id={props.id} className={props.className}>
        SignalR <i id={props.id + '_signalRIcon'} className="pi pi-circle-fill" style={iconStyle}>
        </i>
    </span>
}


export default SignalRIndicator