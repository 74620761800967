import React from 'react';
import { Button } from 'primereact/button';

export const CopyControl = (props) => {
    const { bgcolor } = props
    return (
        <Button
            icon="pi pi-copy"
            className={bgcolor}
            rounded
            onClick={() => navigator.clipboard.writeText(props.content)}
            style={props.style} />
    )
}

