import { createSelector } from 'reselect';
import { selectAvApiHost } from 'globalSelectors'
import { selectBody } from 'components/Body/store/selectors'

export const selectBodyDatEntryApp = (store) => selectBody(store).dataEntry
export const selectBodyDataEntryAudioVisual = (store) => selectBodyDatEntryApp(store).audioVisualResults

const fetchRequest = (store, count, page, filter, sort, url) => ({ count, page, filter, sort, url, status: store.status })

export const selectAudiovisualUrl = createSelector(
    [selectAvApiHost],
    host => `${host}/Programme`
)

export const selectAudiovisualCurrentPage = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.currentPage
)

export const selectAudiovisualTotalPages = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.totalPages
)

export const selectAudiovisualRows = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.row
)

export const selectAudiovisualFirst = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.first
)

export const selectAudiovisualFilter = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.filter
)

export const selectAudiovisualSort = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.sort
)

export const selectAudiovisualFetchRequest = createSelector(
    [selectBodyDataEntryAudioVisual, selectAudiovisualRows, selectAudiovisualCurrentPage, selectAudiovisualFilter, selectAudiovisualSort, selectAudiovisualUrl],
    fetchRequest
)

export const selectAudiovisualFetchResults = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.results
)

export const selectAudiovisualFetchStatus = createSelector(
    [selectBodyDataEntryAudioVisual],
    (av) => av.status
)


   

