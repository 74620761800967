import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setBreadCrumbStack } from 'components/Header/SecondaryNavigation/store/actions'
import { AudioVisualContent } from './AudioVisualContent';

export function AudioVisualLanding(props) {
    const location = useLocation()
    const dispatch = useDispatch()
    const setBreadCrumb = (stack) => dispatch(setBreadCrumbStack(stack))

    const breadcrumbItem = {
        label: 'AV & Radio',
        path: location.pathname
    }

    setBreadCrumb([breadcrumbItem])

    return (
        <div className='application-content d-flex flex-column flex-grow-1' >
            <div className='container-fluid d-flex flex-column flex-grow-1'>
                <div className='row d-flex flex-grow-1'>
                    <div className='app-content flex-grow-1'>
                        <div className='alcs-panel'><AudioVisualContent id={props.id} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}