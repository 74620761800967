import { useSelector } from 'react-redux'
import { selectAvApiHost } from 'globalSelectors'
import { Button } from 'primereact/button'

const buttonClassName = 'btn btn-primary'
const relativeSignOutUrl = '/MicrosoftIdentity/Account/SignOut/OpenIdConnect'

// ReSharper disable once InconsistentNaming
const LogOutButton = function (props) {

    const host = useSelector(selectAvApiHost)

    const signOutUrl = host + relativeSignOutUrl

    const handleSignOut = () => {
        window.location.replace(signOutUrl);
        sessionStorage.removeItem('authToken');
    }
    return (
        <Button
            label="Sign Out"
            id={props.id}
            className={props.className} 
            onClick={handleSignOut}>            
        </Button>
    )

}
export default LogOutButton
