import { React } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectTvFilmRadioFetchResults, selectTvFilmRadioFetchRequest, selectAvCurrentPage, selectAvTotalPages, selectTvFilmRadioAvRows, selectTvFilmRadioAvFirst, selectTvFilmRadioFetchStatus, selectAvFilter, selectAvSort } from 'components/Body/Search/SearchResultsPanels/store/selectors'
import { setTvFilmRadioFetchStatusAction, setTvFilmRadioFetchResultsAction, setAvTotalPagesAction, setAvFirstAction, setAvRowsAction, setAvCurrentPageAction, setAvExcelExportAction, setAvCsvExportAction, setAvFilterAction, setAvSortAction } from './store/actions'
import { selectAvApiHost } from 'globalSelectors'
import CommonGrid from 'components/common/CommonGrid'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button';
import { push } from 'redux-first-history';
import { FilterMatchMode } from 'primereact/api'
import FullTextSearch from 'api/FullTextSearch'
import { Badge } from 'primereact/badge'
import { fileDownload } from 'api/FileDownload/fileDownload'

const csvDownloadPathSuffix = '/search/csvexport?searchTerm='
const csvDownloadName = 'AudioVisual.csv'
const excelDownloadPathSuffix = '/search/excelexport?searchTerm='
const excelDownloadName = 'AudioVisual.xlsx'

export function AudioVisual(props) {
    const searchAvResults = useSelector(selectTvFilmRadioFetchResults)
    const currentPage = useSelector(selectAvCurrentPage)
    const first = useSelector(selectTvFilmRadioAvFirst)
    const rows = useSelector(selectTvFilmRadioAvRows)
    const filter = useSelector(selectAvFilter)
    const sort = useSelector(selectAvSort)
    const originalQuery = useSelector(selectTvFilmRadioFetchRequest)
    const avApiHost = useSelector(selectAvApiHost)

    const dispatch = useDispatch()

    const csvDownloadPath = avApiHost + csvDownloadPathSuffix + originalQuery.term
    const excelDownloadPath = avApiHost + excelDownloadPathSuffix + originalQuery.term
    let avResultList = []
    let avSearchResultsCount = 0

    if (searchAvResults) {
        avResultList = searchAvResults['results']
        avSearchResultsCount = searchAvResults['total']
    }
    const displayType = (rowData) => {
        return <i className={rowData.iconId === 1 ? "fa-light fa-tower-cell" : rowData.iconId === 2 ? "fa-light fa-film" : rowData.iconId === 3 ? "fa-light fa-tv-retro" : "fa-light fa-question"} />
    }

    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }

    const handleEpisodeNavigation = (rowData) => {
        navigateTo(`/AudioVisual/${rowData.programmeId}/${rowData.episodeId}`)
    }

    const displayEpisode = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <div className="d-flex ml-auto">
                    <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    <Button className="white ml-2" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleEpisodeNavigation(rowData)} />
                </div>
            </div>
        )
    }

    const displayEpisodeTitle = function (rowData) {
        return displayEpisode(rowData, 'episodeTitle')
    }

    const displayEpisodeCode = function (rowData) {
        return displayEpisode(rowData, 'episodeCode')
    }

    const handleProgrammeNavigation = (rowData) => {
        navigateTo(`/AudioVisual/${rowData.programmeId}`)
    }

    const displayProgramme = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <div className="d-flex ml-auto">
                    <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    <Button className="white ml-1" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleProgrammeNavigation(rowData)} />
                </div>
            </div>
        )
    }

    const displayProgrammeTitle = function (rowData) {
        return displayProgramme(rowData, 'programmeTitle')
    }

    const displayProgrammeCode = function (rowData) {
        return displayProgramme(rowData, 'programmeCode')
    }

    const displayIdaCode = function (rowData) {
        return displayIda(rowData, 'episodeIda')
    }
    const displayIda = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                {rowData[field] && (
                    <div className="d-flex ml-auto">
                        <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    </div>
                )}
            </div>
        )
    }

    const displayActions = function (rowData) {
        return (
            <div id={props.id + '_avList_commonGrid_actions'}>
            </div>
        )
    }


    const header = (
        <div>
            <div className="row d-flex justify-content-flexend">
                <div className="col mt-1">
                    <Badge value={`${first + 1}-${Math.min(first + rows, avSearchResultsCount)}`}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>of</b> </span>
                    <Badge value={avSearchResultsCount}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>Results</b> </span>
                </div>

                <div className="col-auto">
                    <Button
                        id={props.id + '_container_dataTable_export_csv'}
                        className="mr-3 ml-2"
                        icon="fa-light fa-file-import"
                        label="CSV Export"
                        onClick={() => fileDownload(csvDownloadPath, csvDownloadName)}
                    >
                    </Button>
                    <Button
                        id={props.id + '_container_dataTable_export_excel'}
                        label="Excel Export"
                        icon="fa-light fa-file-import"
                        onClick={() => fileDownload(excelDownloadPath, excelDownloadName)}
                    >
                    </Button>
                </div>
            </div>
        </div>
    );

    const avColumnDefinitions = [
        { title: 'Type', mapsToField: 'iconId', template: displayType },
        { title: 'Programme Code', mapsToField: 'programmeCode', filter: true, template: displayProgrammeCode, sortable: true },
        { title: 'Programme Title', mapsToField: 'programmeTitle', template: displayProgrammeTitle, filter: true, sortable: true },
        { title: 'Episode Code', mapsToField: 'episodeCode', filter: true, template: displayEpisodeCode, sortable: true },
        { title: 'Episode Title', mapsToField: 'episodeTitle', filter: true, template: displayEpisodeTitle, sortable: true },
        { title: 'Episode No', mapsToField: 'episodeNumber', filter: true, sortable: true },
        { title: 'Series No', mapsToField: 'episodeSeries', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'Year Produced', mapsToField: 'episodeYear', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'IDA No.', mapsToField: 'episodeIda', template: displayIdaCode, filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { expand: true, title: 'Contributors', mapsToField: 'Contributors', sortable: false, filter: false, template: displayActions }
    ]

    return (
        <div id={props.id + '_avList'} >
            <FullTextSearch
                id={props.id + '_container_results_FullTextSearch'}
                key={currentPage + selectTvFilmRadioAvRows}
                setStatusAction={setTvFilmRadioFetchStatusAction} // Use the provided action for fetching results
                setResultsAction={setTvFilmRadioFetchResultsAction}
                requestSelector={selectTvFilmRadioFetchRequest}
            />
            <CommonGrid
                id={props.id + '_avList_commonGrid'}
                keyColumnName="id"
                data={avResultList}
                totalCount={avSearchResultsCount}
                emptyMessage="No Results"
                columnDefinitions={avColumnDefinitions}
                selectTotalPages={selectAvTotalPages}
                selectFirst={selectTvFilmRadioAvFirst}
                selectRows={selectTvFilmRadioAvRows}
                fetchStatusSelector={selectTvFilmRadioFetchStatus}
                setTotalPagesAction={setAvTotalPagesAction}
                setCurrentPageAction={setAvCurrentPageAction}
                setFirstAction={setAvFirstAction}
                setRowsAction={setAvRowsAction}
                setFilterAction={setAvFilterAction}
                setSortAction={setAvSortAction}
                currentPage={currentPage}
                filter={filter}
                sort={sort}
                header={header}
                csvDownloadPath={csvDownloadPath}
                csvDownloadName={csvDownloadName}
                excelDownloadPath={excelDownloadPath}
                excelDownloadName={excelDownloadName}
            />
        </div>
    )
}