// I wanted a generic method which brought back a Url, however there will be different urls, depending on the
// "type" we want. This is a list of "types". Each will take us to a different "page"

// I've deliberately made these all singular, just for consistency. It doesn't matter
// if you change it, but please be consistent across all values.
export const scribeUrlType = Object.freeze({
    AudioVisual: 0,
    Contact: 1,
    PublishedWork: 2,
    Serial: 3,
    VisualArt: 4,
    Search: 5
});