import { selectBodyAppSettings, selectBody } from '../../store/selectors'

export const selectBodySearchAppSettings = (store) => selectBodyAppSettings(store).Search
export const selectBodySearchApp = (store) => selectBody(store).search
export const selectSearchResultsTabSelect = (store) => selectBodySearchApp(store).searchResultsTabSelect.searchResultsTabSelect



























