import { React } from 'react'

import { useDispatch } from 'react-redux'
import { setSearchResultsTabSelect } from 'components/Body/Search/store/actions'
import { searchResultsTab } from 'modules/enums'
import { selectTvFilmRadioFetchRequest, selectTvFilmRadioFetchResults, selectAvTabLink } from './store/selectors'
import { setTvFilmRadioFetchStatusAction, setTvFilmRadioFetchResultsAction } from './store/actions'
import { avTemplate } from 'components/common/PreviewTemplate'
import FullTextSearch from 'api/FullTextSearch'
import SearchResultsTemplate from 'components/common/SearchResultsTemplate'

// ReSharper disable once InconsistentNaming
function AudioVisualResultsList(props) {
    const dispatch = useDispatch()
    const handleAvTitleClick = (event) => {
        event.preventDefault();
        dispatch(props.setPanelIsVisible(false))
    }
    const setTab = () => dispatch(setSearchResultsTabSelect(searchResultsTab.AudioVisual))
    const selectors = {
        fetchResults: selectTvFilmRadioFetchResults,
        tabLink: selectAvTabLink
    }
    const commonElements = {
        searchComponent: <FullTextSearch
            id={props.id + '_avAllList_FullTextSearch'}
            setStatusAction={setTvFilmRadioFetchStatusAction}
            setResultsAction={setTvFilmRadioFetchResultsAction}
            requestSelector={selectTvFilmRadioFetchRequest}
        />,
        title: 'AV & Radio',
        template: avTemplate,
        setTab: setTab,
        searchTerm: props.searchTerm,
        setPanelVisible: handleAvTitleClick
    };

    return (
        <SearchResultsTemplate
            id={props.id + '_avList'}
            selectors={selectors}
            commonElements={commonElements}
        />
    )
}
export default AudioVisualResultsList