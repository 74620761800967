import { createConnection } from './connection'
import { useSelector, useDispatch } from 'react-redux';
import { selectSignalRConfig } from 'providers/store'
import { createCallbacks } from './callbacks'
import { connectedAction, connectedFailedAction, setStatusAction } from './store/actions'
import { connectionIdSelector, statusSelector } from './store/selectors'
import { connectionErrorSelector } from './store/selectors'

// ReSharper disable once InconsistentNaming
const SignalR = function(props) {

    console.log('SignalR - props', props)

    /* Dispatch */
    const dispatch = useDispatch()
    const callbacks = createCallbacks(dispatch)
    //const connected = (id) => dispatch(connectedAction(id))
    const setStatus = (statum) => dispatch(setStatusAction(statum))

    /* Selectors */
    const config = useSelector(selectSignalRConfig)
    const connectionId = useSelector(connectionIdSelector)
    const status = useSelector(statusSelector)
    const connectionError = useSelector(connectionErrorSelector)

    const connected = status === 'Connected'

    if (config && config.url && status === 'Idle') {
        const connection = createConnection(config.url, callbacks)
        connection.onclose(() => {
            setStatus(connection.state)
        })
        connection.onreconnecting(() => {
            setStatus(connection.state)
        })
        connection.onreconnected(() => {
            setStatus(connection.state)
        })

        try {
            connection.start()
                .then((res) => {
                    console.log('res', res)
                    setStatus(connection.state)
                })
            setStatus(connection.state)
        }
        catch (err) {
            console.error(err)
            connectedFailedAction(err)
        }

    }

    return <div id={props.id} state={status}>
               { /*{!connected && <p>SignalR Connecting...</p>}*/
               }
               { /*{!!connected && !!connectionError && <p>{connectionError}</p>}*/
               }
               { /*{!!connected && !!connectionError || props.children}*/
               }
               {props.children}
           </div>
}

export default SignalR
