import { createSelector } from 'reselect'
import { selectAppSettings } from '../../store'

const selectAuthState = (store) => store.authentication
//const selectAuthenticationAppSettings = (store) => selectAppSettings(store).authentication

export const selectAuthenticationStatus = createSelector([selectAuthState], (auth) => auth.isAuthenticated)
export const selectDepartments = createSelector([selectAuthState], (auth) => auth.departments)
export const selectScopes = createSelector([selectAuthState], (auth) => auth.scopes)
export const selectError = createSelector([selectAuthState], (auth) => auth.error)
//export const selectSignInUrl = createSelector([selectAuthenticationAppSettings], (appSettings) => {
//    console.log(appSettings)
//    return appSettings.url
//})
