export const debounce = (timeout, action) => {
    let timerId = null

    return (...args) => {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            action(...args)
        }, timeout)
    }
}
