import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setBreadCrumbStack, pushBreadcrumbItemToStack } from 'components/Header/SecondaryNavigation/store/actions'
import DataEntry from 'api/DataEntry/AudioVisual'
import DataUpdate from 'api/DataUpdate/AudioVisual'
import { setShowDataUpdateAction, setShowDataCreateAction, setIsNewProgrammeAction, setProgrammeFetchStatusAction, setProgrammeFetchResultsAction, setProgrammeDraftResultsSapCodeAction, setEpisodeFetchResultsAction, setProgrammeOriginalAggregateVersionAction, setProgrammeGuidAction, setProgrammeUpdateStatusAction, setProgrammeDraftResultsProgrammeTypeAction, setProgrammeDraftResultsFormatTypeAction, clearProgrammeDraftResultsAction, clearProgrammeDraftResultsEpisodesAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { selectProgrammeFetchRequest as selectprogrammeFetchRequest, selectEpisodeResultPresentation, selectUpdateStatus, selectUpdateErrorDetailMessage, selectProgrammeUpdateRequest, selectProgrammeGuid, selectAudioVisualResultPresentation, selectShowDataUpdate, selectShowDataCreate, selectDataUpdateKey } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { Toast } from 'primereact/toast'
import { ConfirmPopup } from 'primereact/confirmpopup'
import DataCreate from 'api/DataCreate/AudioVisual'
import { ProgrammeDetails } from 'components/Body/data-entry/AudioVisual/ProgrammeDetails'
import { EpisodeDetails } from 'components/Body/data-entry/AudioVisual/EpisodeDetails'

// ReSharper disable once InconsistentNaming
export function AudioVisualDetails(props) {
    // This component now has a props.id and a props.className 
    const toast = useRef(null)
    /* Dispatch */
    const dispatch = useDispatch()
    const setAcceptedProgrammeType = (typeId) => dispatch(setProgrammeDraftResultsProgrammeTypeAction(typeId))
    const setAcceptedFormatType = (typeId) => dispatch(setProgrammeDraftResultsFormatTypeAction(typeId))
    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const clearProgrammeDraftResults = () => dispatch(clearProgrammeDraftResultsAction())
    const setBreadCrumb = (stack) => dispatch(setBreadCrumbStack(stack))
    const pushBreadCrumbItem = (item) => dispatch(pushBreadcrumbItemToStack(item))
    const setProgrammeGuid = (guid) => dispatch(setProgrammeGuidAction(guid))
    const setShowDataUpdate = (show) => dispatch(setShowDataUpdateAction(show))
    const setShowDataCreate = (show) => dispatch(setShowDataCreateAction(show))

    /* Selectors */
    const programmeGuid = useSelector(selectProgrammeGuid)
    const resultsDataForEdit = useSelector(selectAudioVisualResultPresentation)
    const showDataUpdate = useSelector(selectShowDataUpdate)
    const showDataCreate = useSelector(selectShowDataCreate)
    const dataUpdateKey = useSelector(selectDataUpdateKey)
    const { languages, programmeTypes, programmeFormatTypes } = useSelector(selectAllAssets)
    const updateStatus = useSelector(selectUpdateStatus)
    const errorMessage = useSelector(selectUpdateErrorDetailMessage)
    var episodeData = useSelector(selectEpisodeResultPresentation)
    const validFilmFormatIds = [1, 5]
    const isFilm = resultsDataForEdit && validFilmFormatIds.includes(resultsDataForEdit.formatId)

    useEffect(() => {
        if (updateStatus.status === 'complete') {
            dispatch(setProgrammeUpdateStatusAction('idle'))
            dispatch(setProgrammeFetchResultsAction(resultsDataForEdit))
            dispatch(setEpisodeFetchResultsAction(episodeData))
            setShowDataUpdate(false)
            setShowDataCreate(false)
            clearProgrammeDraftResults()
            clearProgrammeEpisodeDraftResults()
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Update successful', life: 3000 })
            setTimeout(() => {
                setProgrammeGuid('')
            }, 1000);

        } else if (updateStatus.status === 'error') {
            dispatch(setProgrammeUpdateStatusAction('idle'))
            setShowDataUpdate(false)
            setShowDataCreate(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${errorMessage}`, life: 3000 })
        }
    }, [updateStatus, errorMessage])

    /* Params */
    const params = useParams()
    const episodeId = params.episodeId
    const isEpisodeRequired = params.episodeId !== undefined
    /* Declarations */

    const id = params.id || '*new*'
    const isProgrammeDataRequired = programmeGuid !== id
    let primaryTitle = null
    let altTitles = null
    const assetLanguages = languages

    if (isProgrammeDataRequired) {
        setProgrammeGuid(id)
    }

    /* Data */
    const { programmeTypeId, formatId, isRadio } = resultsDataForEdit || {}
    let selectionValue = (isRadio ? 2 : 1) | programmeTypeId
    const availableTypes = programmeTypes.filter((t) => (t.consumedByTypes & selectionValue) > 0)
    const availableFormats = programmeFormatTypes.filter((t) => (t.consumedByProgrammeTypes & selectionValue & 3) > 0 && (t.consumedByProgrammeTypes & selectionValue & ~3))


    if (programmeTypeId > 0 && availableTypes.filter((t) => t.id === programmeTypeId).length === 0) {
        setAcceptedProgrammeType(0)
    }

    if (formatId > 0 && availableFormats.filter((t) => t.id === formatId).length === 0) {
        setAcceptedFormatType(0)
    }

    if (resultsDataForEdit && resultsDataForEdit.titles) {
        const titlesArray = Object.values(resultsDataForEdit.titles)
        primaryTitle = titlesArray.find(title => title.titleTypeId === 1)
        altTitles = titlesArray.filter(title => title.titleTypeId !== 1)
    }

    const handleUpdateComplete = () => {
        setShowDataUpdate(false)
    }
    const handleCreateComplete = () => {
        setShowDataCreate(false)
    }

    if (isProgrammeDataRequired) {
        return (<DataEntry
            id={props.id + '_DataEntry_audioVisual'}
            key={props.id}
            setNewProgrammeStatus={setIsNewProgrammeAction}
            setStatusAction={setProgrammeFetchStatusAction} // Use the provided action for fetching results
            setOriginalResultAction={setProgrammeFetchResultsAction}
            requestSelector={selectprogrammeFetchRequest}
        />)
    }

    const avAndRadioLandingBreadCrumbItem = {
        label: 'AV & Radio',
        path: '/AudioVisual'
    }
    setBreadCrumb([avAndRadioLandingBreadCrumbItem])

    if (primaryTitle && primaryTitle.title) {
        const avAndRadioProgrammeBreadCrumbItem = {
            label: primaryTitle.title,
            path: `/AudioVisual/${id}`
        }
        pushBreadCrumbItem(avAndRadioProgrammeBreadCrumbItem)
    }

    let episodePrimaryTitle = episodeData && episodeData.titles && episodeData.titles.length > 0 && episodeData.titles.find(title => title.titleTypeId === 1)
    if (episodePrimaryTitle) {
        const avAndRadioEpisodeBreadCrumbItem = {
            label: episodePrimaryTitle.title,
            path: `/AudioVisual/${id}/${episodeId}`
        }
        pushBreadCrumbItem(avAndRadioEpisodeBreadCrumbItem)
    }


    return (
        <div className='application-content d-flex flex-column flex-grow-1' >
            {showDataUpdate && (
                <DataUpdate
                    id={props.id + '_DataUpdate_audioVisual'}
                    key={dataUpdateKey}
                    setStatusAction={setProgrammeUpdateStatusAction}
                    requestSelector={selectProgrammeUpdateRequest}
                    setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                    onComplete={handleUpdateComplete}
                    episodeOnly={false}
                />
            )}
            {showDataCreate && (
                <DataCreate
                    id={props.id + '_DataCreate_audioVisual'}
                    key={dataUpdateKey}
                    setStatusAction={setProgrammeUpdateStatusAction}
                    setSapCodeAction={setProgrammeDraftResultsSapCodeAction}
                    setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                    requestSelector={selectProgrammeUpdateRequest}
                    setNewProgrammeStatus={setIsNewProgrammeAction}
                    onComplete={handleCreateComplete}
                    episodeOnly={false}
                />
            )}
            <div className='container-fluid d-flex flex-column flex-grow-1'>
                <div className='row d-flex flex-grow-1'>
                    <div className='col-3 d-flex flex-column flex-grow-1'>
                        <ProgrammeDetails className={'app-sidebar flex-grow-1'} toast={toast} programmeGuid={programmeGuid} primaryTitle={primaryTitle} altTitles={altTitles} episodePrimaryTitle={episodePrimaryTitle} resultsDataForEdit={resultsDataForEdit} isFilm={isFilm} />
                    </div>
                    <div className='col-9 d-flex flex-column flex-grow-1'>
                        <Toast ref={toast} />
                        <ConfirmPopup />
                        <EpisodeDetails className={'app-content flex-grow-1'} resultsDataForEdit={resultsDataForEdit} toast={toast} primaryTitle={primaryTitle} altTitles={altTitles} isEpisodeRequired={isEpisodeRequired} episodeId={episodeId} isFilm={isFilm} assetLanguages={assetLanguages} />
                    </div>
                </div>
            </div>
        </div>
    )
}
