import { ScrollPanel } from 'primereact/scrollpanel'

// ReSharper disable once InconsistentNaming
const OtherResults = (props) => (
    <div class="alcs-inner-panel medium-grey">
        <div class="header"><span class="title">Other Results</span></div>
        <div class="content">
            <ScrollPanel style={{ width: props.width, height: props.height }}>
                <b id={props.id + '_otherResults_title'}>Other Results
                </b>
                <br />
                <div id={props.id + '_otherResults_container'} >Future Implementation</div>
            </ScrollPanel>
        </div>
    </div>
)

export default OtherResults