import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel'
import { Dropdown } from 'primereact/dropdown'
import { SplitButton } from 'primereact/splitbutton'
import { useSelector, useDispatch } from 'react-redux'
import { selectEpisodetPresentationEpisodeTitles, selectAreEpisodeLanguagesInvalid, selectAreEpisodeTitlesInvalid, selectEpisodeTitles } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setEpisodeDraftResultsTitleAction, setEpisodeTitles } from 'components/Body/data-entry/AudioVisual/store/actions'
import { Toast } from 'primereact/toast'


// ReSharper disable once InconsistentNaming
export const OtherTitles = (props) => {
    const toast = useRef(null)
    const { altEpisodeTitles, isFilm, languages } = props
    const presentationTitles = useSelector(selectEpisodetPresentationEpisodeTitles)
    const altPresentationTitles = presentationTitles && presentationTitles.length > 0 && presentationTitles?.filter(title => title.titleTypeId !== 1)
    const titles = useSelector(selectEpisodeTitles)   
    const areLanguagesInvalid = useSelector(selectAreEpisodeLanguagesInvalid)
    const areTitlesInvalid = useSelector(selectAreEpisodeTitlesInvalid)
    const [draftTitles, setDraftTitles] = useState({})
    const dispatch = useDispatch()
    const setAcceptedTitles = (titlesToAccept) => dispatch(setEpisodeDraftResultsTitleAction(titlesToAccept))
    const setTitles = (titleList) => dispatch(setEpisodeTitles(titleList))

    const setInitialTitles = (alternativePresentationTitles) => {
        setTitles(alternativePresentationTitles)
    }

    useEffect(() => {
        if (isFilm) {
            setTitles(altEpisodeTitles)
        }
        else if (altPresentationTitles) {
            setInitialTitles(altPresentationTitles)
        }
        else if (!altPresentationTitles) {
            if (!isFilm) {
                const newTitle = { title: '', languageId: null, titleTypeId: 2 }
                setTitles([newTitle])
            }
        }
    }, [presentationTitles])


    if (!isFilm && (!titles || titles.length === 0) && Object.keys(draftTitles).length === 0) {
        const newTitle = { title: '', languageId: null, titleTypeId: 2 }
        setTitles([newTitle])        
    }

    //Split Save from Split Button

    const splitSave = () => {

    }

    //Add New Row

    const addNewRow = (index) => {
        const newTitle = { title: '', languageId: null, titleTypeId: 2 }
        const newTitles = [
            ...titles.slice(0, index + 1),
            newTitle,
            ...titles.slice(index + 1)
        ]
        setTitles(newTitles)
        toast.current.show({ severity: 'info', summary: 'Add', detail: 'New Row Added' })
    }

    //Delete Row

    const deleteRow = (index) => {        
        const newTitles = titles.filter((_, i) => i !== index)
        setTitles(newTitles)
        setDraftTitles(newTitles)
        const mainTitle = presentationTitles?.find(title => title.titleTypeId === 1)
        const combinedTitles = mainTitle ? [mainTitle, ...newTitles] : newTitles
        setAcceptedTitles(combinedTitles)
        toast.current.show({ severity: 'info', summary: 'Delete', detail: 'Title set to Delete in Draft' })
    }


    //Add and Delete Row split button options

    const buttonModelItems = [
        {
            label: 'Add',
            icon: 'fa-thin fa-plus',
            command: (index) => addNewRow(index)
        },
        {
            label: 'Delete',
            icon: 'fa-light fa-minus',
            command: (index) => deleteRow(index)
        }
    ]

    const buttonModelItemsWithIndex = (index) => buttonModelItems.map(item => {
        return { ...item, command: () => item.command(index) }
    })

    const handleInputChange = (index, field, value) => {
        const newTitles = titles.map((title, i) => {
            if (i === index) {

                return {
                    ...title,
                    [field]: value
                }
            }
            return title
        })
        setTitles(newTitles)
        const mainTitle = presentationTitles?.find(title => title.titleTypeId === 1)
        // Combine newTitles with mainTitle
        const combinedTitles = mainTitle ? [mainTitle, ...newTitles] : newTitles
        // Set the combined titles
        setAcceptedTitles(combinedTitles)
    }

    const mergeTitles = (titlesToMergeInto, updatedDraftTitles) => {
        const titleMap = new Map(titlesToMergeInto.map(title => [title.title, title]))

        Object.values(updatedDraftTitles).forEach(draftTitle => {
            titleMap.set(draftTitle.title, draftTitle)
        })

        return Array.from(titleMap.values())
    }

    return (
        <fieldset className="mt-2">
            <legend>Other Titles </legend>
            <div className="other-titles">
                <Toast ref={toast} />
                <ScrollPanel className="mt-2" style={{ height: '180px' }}>
                    {titles && titles.length > 0 && (titles.map((title, index) => {
                        const languageId = titles[index]?.languageId
                        return (
                            <div key={index} className="row mb-2" style={{ borderBottom: '1px dotted #000', paddingBottom: '10px' }}>
                                <div className="col-md-5">
                                    <p className="label-input mb-1">Language</p>
                                    <Dropdown id="language" value={titles && titles[index] && titles[index].languageId} options={languages} filter onChange={(e) => handleInputChange(index, 'languageId', e.value.id)} placeholder={titles[index].languageId && languages.find(lang => lang.id === titles[index].languageId)?.name}
                                        className="custom-full-width mb-2" style={{ height: '53%' }} invalid={areLanguagesInvalid[index]} optionLabel="name"
                                        optionValue="id" disabled={isFilm} />
                                </div>
                                <div className="col-md-5">
                                    <p className="label-input mb-1">Title</p>
                                    <InputText id="title" value={titles && titles[index] && titles[index].title} onChange={(e) => handleInputChange(index, 'title', e.target.value)} className=" av-radio-option form-control mb-2 custom-full-width" disabled={isFilm} invalid={areTitlesInvalid[index]} />
                                </div>
                                <div className="col-md-2">
                                    {!isFilm && (
                                        <SplitButton className='modal-only' rounded onClick={splitSave} dropdownIcon="fa-light fa-plus-minus" model={buttonModelItemsWithIndex(index)} style={{ marginTop: '34px' }} />
                                    )}
                                </div>
                            </div>
                        )
                    }))}
                </ScrollPanel>
            </div>
        </fieldset>
    )
}