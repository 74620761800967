
import { combineReducers } from 'redux'
import { actionNames } from './actions'
import { searchPreviewReducer } from '../SearchPreview/store'


const searchTermReducer = (state = '', { type, payload }) => {
    switch(type) {
        case actionNames.SetSearchTermActionName:
            return payload
        default:
            return state
    }
}

const searchHasFocusReducer = (state = false, { type, payload }) => {
    switch (type) {
        case actionNames.SetHasLostFocusActionName:
        case actionNames.SetHasGainedFocusActionName:
            return payload
        default:
            return state
    }
}

export const globalSearchBarReducer = combineReducers({
        searchTerm : searchTermReducer,
        searchHasFocus : searchHasFocusReducer,
        searchPreview : searchPreviewReducer
    })