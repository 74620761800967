import { useDispatch, useSelector } from 'react-redux';
import { setAssetsAction } from './store/actions';
import { selectAllAssets, selectAssetsError } from './store/selectors';
import {selectAvApiHost} from '../../globalSelectors' 

// ReSharper disable once InconsistentNaming
const AssetLoader = (props) => {

    console.log('Assets - props', props)

    /* Dispatch */
    var dispatch = useDispatch()
    const setAssets = (result) => dispatch(setAssetsAction(result))

    /* Selectors */
    const assets = useSelector(selectAllAssets)
    const assetsError = useSelector(selectAssetsError)
    const avApiHost = useSelector(selectAvApiHost)

    const assetsEndpoint = `${avApiHost}/assets`;

    if (!assets && !assetsError) {
        assetsAsync(assetsEndpoint)
            .then(assetData => setAssets(assetData))
    }

    /* NB:
     * (assetsError && something) // if assetsError is "truthy" then evaluate "something"
     * otherwise just false
     *
     * (assetsError || something) // if assetsError is "falsy" then evaluate "something"
     * otherwise just true
     */

    return <div id={props.id}>
        {!!assetsError && <div id={props.id}>{`Unable to load assets: ${assetsError}`}</div>}
        {!!assetsError || !!assets ||
            (<span id={props.id + '_assetsLoader_message'}>attempting to load assets..</span>)}
        {!!assetsError || (!!assets && props.children)}
    </div>
}

const assetsAsync = async (assetsEndpoint) => {   
    const controller = new AbortController()
    const signal = controller.signal
    const timeOut = setTimeout(() => {
        controller.abort()
        return ({
            error: 'Time out occurred while trying to access the assets.'
        })
    }, 5000)
    
    try {
        
        const response = await fetch(assetsEndpoint, {
            signal
        })

        return response.json()

    }
    catch (err) {
        return err
    }
    finally {
        clearTimeout(timeOut)
    }

}

export default AssetLoader;
