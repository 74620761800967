import React, { useState, useRef } from "react";
import { useRefAsState } from 'modules/React.Extensions'
import { selectAuthApiHost } from 'globalSelectors'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from 'primereact/dialog';
import moment from 'moment'
import { CopyControl } from 'components/common/CopyControl'
import { selectAboutBoxRequested } from './store/selectors'
import { aboutPanelRequestedAction } from './store/actions'
import { authFetch } from 'api/auth/authFetch'


// ReSharper disable once InconsistentNaming
const AboutPanel = () => {

    const [currentVersion, setCurrentVersion] = useRefAsState()
    const [clipboardString, setClipboardString] = useRefAsState()
    
    const [showDialog, setShowDialog] = useState(false)
    const [serverErrorOccurred, setServerErrorOccurred] = useState(false)

    const host = useSelector(selectAuthApiHost)
    const panelRequested = useSelector(selectAboutBoxRequested)
    
    const dispatch = useDispatch()
    const aboutBoxRequested = (visible) => dispatch(aboutPanelRequestedAction(visible))
    
    const onHideDialog = () => {
        setServerErrorOccurred(false) 
        setShowDialog(false)
    }

    if (panelRequested) {
        
            const apiUrl = `${host}/version`

            authFetch(apiUrl, {
                method: 'GET',
                headers: {
                    "Content-Type": 'application/json'
                }
            }).then ((response) => {
                if (!response.ok) {
                    console.log('Network Response was not ok')
                    console.log(response)
                    aboutBoxRequested(false)
                    setServerErrorOccurred (true)
                }
                else {
                    response.json()
                        .then((parsedResponse) => {

                            const environment = parsedResponse.environment
                            const versionNumber = parsedResponse.versionNumber
                            const formattedDate = moment(parsedResponse.timestamp)
                                .format('LLL')
                            setCurrentVersion( {
                                environment,
                                versionNumber,
                                formattedDate
                            })
                            setClipboardString(`${environment} ${versionNumber}`)
                            aboutBoxRequested(false)
                            setShowDialog(true)
                            setServerErrorOccurred(false)
                        }).catch((error) => {
                            console.error('There was an error parsing the data:', error)
                            aboutBoxRequested(false)
                            setServerErrorOccurred(true)
                        })

                }
            }).catch((error) => {
                console.error('There was an error retrieving the data:', error)
                aboutBoxRequested(false)
                setServerErrorOccurred(true)
            })

            return undefined
    }

    if (serverErrorOccurred) {
        return (
            <Dialog
                header="About Scribe"
                visible={true}
                style={{ width: '25vw' }}
                onHide={onHideDialog}
                modal={true}
                focusOnShow={false}
                dismissableMask={true}>
                <p>
                    There was a problem reading the Version data.
                </p>
            </Dialog>
        )
    }
    if (showDialog) {
        return (
            <Dialog
                header="About Scribe"
                visible={true}
                style={{ width: '25vw' }}
                onHide={ onHideDialog }
                modal={true}
                focusOnShow={false}
                dismissableMask={true}>
                <p>
                    Environment: { currentVersion.environment }
                </p>
                <p>
                    Version: {currentVersion.versionNumber}&nbsp;&nbsp;<CopyControl content={ clipboardString}/>
                </p>
                <p>
                    Timestamp: {currentVersion.formattedDate}
                </p>
            </Dialog>
        )
    }

    return undefined
}

export default AboutPanel