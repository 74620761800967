import { React } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchResultsTabSelect } from 'components/Body/Search/store/actions'
import { searchResultsTab } from 'modules/enums'
import { selectVisualArtsTabLink } from './store/selectors'
import { visualArtTemplate } from 'components/common/PreviewTemplate'
import { ScrollPanel } from 'primereact/scrollpanel'

// ReSharper disable once InconsistentNaming
function VisualArtListPreview(props) {
    /* Dispatch */
    const dispatch = useDispatch()
    const setVisualArtsTab = () => dispatch(setSearchResultsTabSelect(searchResultsTab.VisualArts))


    /* Selectors */
    const visualArtsTabLink = useSelector(selectVisualArtsTabLink)

    let visualArtsResultList = []
    let visualArtsSearchResultsCount = 0

    return (
        <div class="alcs-inner-panel medium-grey">
            <div class="header"><span class="title">Visual Arts</span></div>
            <div class="content">
                <ScrollPanel id={props.id + '_scrollPanel'} style={{ width: props.width, height: props.height }}>
                    <div id={props.id + '_vaList'}>
                        <b id={props.id + '_vaList_title'}>Visual Arts
                            <button id={props.id + '_vaList_title_button'}>{visualArtsSearchResultsCount} results</button>
                        </b>
                        <br />
                        <div id={props.id + '_vaList_tableContainer'} className={'table-responsive'}>
                            {visualArtsResultList && visualArtsResultList.length > 0 ? (
                                <table id={props.id + '_vaList_tableContainer_table'} class="table">
                                    <thead id={props.id + '_vaList_tableContainer_table_head'}>
                                        {/* <!-- Table header goes here -->*/}
                                    </thead>
                                    <tbody id={props.id + '_vaList_tableContainer_table_body'}>
                                        {/*<!-- Use a loop to iterate through visualArtsResultList and generate rows with visualArtTemplate -->*/}
                                        {visualArtsResultList.map((item, index) => (
                                            <tr id={props.id + '_vaList_tableContainer_table_body_row_' + index}>
                                                {/* <!-- Render data using visualArtTemplate -->*/}
                                                {visualArtTemplate(item, props.id + '_vaList_tableContainer_table_body_row_' + index)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p id={props.id + '_vaList_tableContainer_noResultMessage'}>Future Implementation</p>
                            )}
                        </div>
                        <div id={props.id + '_vaList_viewAllContainer'}>
                            <Link id={props.id + '_vaList_viewAllContainer_link'} to={visualArtsTabLink} onClick={setVisualArtsTab}>
                                <span id={props.id + '_vaList_viewAllContainer_link_text'}>View All Visual Arts Results</span>
                            </Link>
                        </div>

                    </div>
                </ScrollPanel>
            </div>
        </div>
    )
}
export default VisualArtListPreview