import React from 'react';
import 'primeicons/primeicons.css'
import 'assets/css/bootstrap-grid.css'
import 'assets/css/bootstrap-spacing.css'
import 'assets/fontawesome/css/fontawesome.css'
import 'assets/fontawesome/css/light.css'
import 'assets/themes/alcs-theme/theme.scss';
import 'assets/css/alcs-theme.css';
import 'assets/css/custom.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'
import Header from 'components/Header'
import Body from 'components/Body'
import Footer from 'components/Footer'

// ReSharper disable once InconsistentNaming
const App = (props) => {

    console.log('App - props: ', props)
    
    return (
        <div id={props.id} className={'alcs-chassis'}>
            <Header id={props.id + '_header'} />
            <div className='application-content d-flex flex-column flex-grow-1'>
                <div className='container-fluid d-flex flex-column flex-grow-1'>
                    <Body id={props.id + '_body'} />
                </div>
            </div>
            <Footer id={props.id + '_footer'} className="footer" />
        </ div>
    )
}

export default App;
