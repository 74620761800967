import { createSelector } from 'reselect'
import { selectRelease } from '../store'


/* Selectors */


export const selectAppVersion = createSelector(
    [selectRelease],
    (release) => `0.${release}.0.0`
)
