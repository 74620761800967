//import logo from 'assets/images/ScribeLogo.svg';

import logo from 'assets/images/alcs-crest-white.svg'
import { Link } from 'react-router-dom'

// ReSharper disable once InconsistentNaming
const SiteLogo = (props) => {
    return (
        <div id='brand-container' className='col-auto d-flex justify-content-center'>
            <Link to="/">
                <img src={logo} className='chassis-brand header-logo' id={props.id + '-brand-logo'} alt='logo' />
            </Link>
        </div>
    )
}

export default SiteLogo
