import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'

const fetchDataBySearch = async (count, page, filter, sort, searchTerm, searchEndpoint, controller) => {

    if (count === 0) {
        return []
    }
    if (page === 0) {
        return[]
    }

    if (!searchTerm || searchTerm.length < 3) {
        throw {
            Message: 'Search Term too short'
        }
    }

    var quoteCount = (searchTerm.match(/\"/g) || []).length
    if (!quoteCount) {
        searchTerm = `"${searchTerm}"`
    }
    else if (quoteCount % 2 !== 0) {
        searchTerm = `${searchTerm}"`
    }

    const queryParamsObject = {
        pageNumber: page.toString(),
        count: count.toString()
    }

    if (filter) {
        queryParamsObject.filter = filter.toString()
    }

    if (sort) {
        queryParamsObject.sortBy = sort.toString()
    }

    const queryParams = new URLSearchParams(queryParamsObject).toString()
    const url = `${searchEndpoint}/search?${queryParams}`;

    let responseCode = -1

    try {

        const response = await authFetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({ Term: searchTerm }),
            signal: controller.signal
        })

        const wasAborted = controller?.signal?.aborted

        controller = null

        if (wasAborted) {
            return null
        }

        responseCode = response.status

        if (responseCode === 200) {
            const json = await response.json()
            return json
        }

        throw {
            Message: 'The search request was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    }
    catch (error) {
        throw {
            Message: 'The search request was not successful',
            StatusCode: responseCode,
            Details: error
        }
    }
}

const setUpFetch = (count, page, filter, sort, searchTerm, searchEndpoint) => {
    const controller = new AbortController()
    return  {
            controller,
        fetch: () => fetchDataBySearch(count, page, filter, sort, searchTerm, searchEndpoint, controller)
        }
}

// ReSharper disable once InconsistentNaming
const FullTextSearch = (props) => {

    const [abortController, setAbortController] = useState(null)
    const [oTerm, setOTerm] = useState('')
    const [oPage, setOPage] = useState(-1)
    const [oSort, setOSort] = useState(null)
    const [oFilter, setOFilter] = useState(null)

    const setStatusAction = props.setStatusAction
    const setResultsAction = props.setResultsAction
    const requestSelector = props.requestSelector

    /* Dispatch */
    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setResults = (results) => dispatch(setResultsAction(results))

    /* Selectors */
    const searchApiRequest = useSelector(requestSelector)

    const { count, page, filter, sort, term, url, status } = searchApiRequest

    if (oTerm === term && oPage === page && oFilter === filter && oSort === sort) {
        return undefined
    }

    setOTerm(term)
    setOPage(page)
    setOFilter(filter)
    setOSort(sort)

    if (status === 'pending' && abortController) {
        abortController.abort()
    }

    setStatus({ status: 'pending' })

    var { controller, fetch } = setUpFetch(count, page, filter, sort, term, url)

    setAbortController(controller)
    fetch()
        .then(results => {
            if (results) {
                setResults(results)
                setStatus({ status: 'complete' })
            }
            //else was aborted
        })
        .catch(error => {
            setResults({})
            setStatus({ status: 'error', error: error })
        })

    return undefined
}

export default FullTextSearch
