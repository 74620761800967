import React, {forwardRef} from 'react'
import { Routes, Route } from 'react-router-dom'
import AppRoutes from 'AppRoutes'

// ReSharper disable once InconsistentNaming
const Body = (props) => {


    return (
        <div id="body">
            <Routes>
                {AppRoutes.map((route, index) => {
                    const {
                        element,
                        ...rest
                    } = route
                    return <Route key={index} {...rest} element={element} />
                })}
            </Routes>
        </div>

    )
}

export default Body

