import { combineReducers } from '@reduxjs/toolkit';
import { searchResultsPanelReducer } from 'components/Body/Search/SearchResultsPanels/store'
//var result = {results: [] , total: 0}
const initialState = {  
    searchResultsTabSelect: 5   
}

const searchResultsTabSelectReducer = (state = initialState, action) => {
    switch (action.type) {       
      
        case 'SetSearchResultsTabSelect':
            return {
                ...state,
                searchResultsTabSelect: action.payload
            }
        default:
            return state
    }
}

export const searchReducer = combineReducers({
    searchResultsPanels: searchResultsPanelReducer,
    searchResultsTabSelect: searchResultsTabSelectReducer
})