import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

//import { selectNotificationsCount } from './store/selectors'

// ReSharper disable once InconsistentNaming
const NotificationBell = (props) => {

    const countSelector = 1//useSelector(selectNotificationsCount)

    return (
        <Button id={props.id} className={`orange ml-3 mb-4 custom-badge`} label="Notifications" icon="fa-light fa-bell" badge={countSelector} raised />
    )
}

export default NotificationBell