import { createSelector } from 'reselect'

const selectStore = store => store

export const noticeConfig = createSelector(
        [selectStore],
        (store) => store.signalR?.notices
    )
export const createAggregateIdSelector = (aggregateId) => {
    return createSelector(
            [selectStore],
            (store) => store.signalR?.notices.find(notice => notice.id === aggregateId)
        )
}

export const connectionIdSelector = (
        [selectStore],
        (store) => store.signalR?.connectionId
    )

export const statusSelector = (
        [selectStore],
    (store) => store.signalR?.status
)

export const connectionErrorSelector = (
        [selectStore],
        (store) => store.signalR?.connectionError
    )
