
const initialState = null

export const appSettingsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case '_appSettings_Set':
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }

}
