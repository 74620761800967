import { useRefAsState } from 'modules/React.Extensions'
import { useDispatch, useSelector } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'
import { transformEpisodeListDraftToEpisodeInfos } from 'modules/data-entry/AudioVisual/dataTransforms'

const updateEpisodeListDataByEndpoint = async (endpoint, episodeDraft, aggregateVersion, controller, setNewAggregate) => {
    let responseCode = -1

    try {
        const transformedBody = transformEpisodeListDraftToEpisodeInfos(episodeDraft);
        const response = await authFetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        if (controller.signal.aborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 200) {
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode } // Return response code and new aggregate version
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const setUpEpisodeUpdate = (endpoint, episodeDraft, aggregateVersion, setNewAggregate) => {
    const controller = new AbortController()
    return {
        controller,
        update: () => updateEpisodeListDataByEndpoint(endpoint, episodeDraft, aggregateVersion, controller, setNewAggregate),
    }
}

// ReSharper disable once InconsistentNaming
const DataListUpdate = (props) => {
    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const requestSelector = props.requestSelector
    const onComplete = props.onComplete
    const setAggregate = props.setAggregateAction

    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setNewAggregate = (newAggregate) => dispatch(setAggregate(newAggregate))

    const dataUpdateRequest = useSelector(requestSelector)
    const { endpoint, status, episodeListDraft, aggregateVersion } = dataUpdateRequest

    const handleUpdate = async () => {
        setStatus({ updateEpisodeListStatus: 'pending' })
        const { controller, update } = setUpEpisodeUpdate(endpoint, episodeListDraft, aggregateVersion, setNewAggregate)

        setAbortController(controller);

        try {
            const result = await update()
            if (result) {
                setStatus({ updateEpisodeListStatus: 'complete' });
                if (onComplete) {
                    setTimeout(() => {
                        onComplete()
                    }, 5000);
                }
            }
        } catch (error) {
            setStatus({ updateEpisodeListStatus: 'error', error: error })
        }
    }

    if (status.updateEpisodeListStatus === 'idle') {
        handleUpdate()
    }

    return null
}

export default DataListUpdate