export const audioVisualReasultsInitialState = {
    status: 'idle',
    results: {

    },
    currentPage: 1,
    visiblePages: [],
    totalPages: 0,
    row: 50,
    first: 0,
    sort: null,
    filter: null
}

export const audioVisualResultsReducer = (state = audioVisualReasultsInitialState, action) => {
    switch (action.type) {
        case 'AUDIOVISUAL_SET_FETCH_STATUS_ACTION':
            return {
                ...state,
                status: action.payload
            }
        case 'AUDIOVISUAL_SET_FETCH_RESULTS_ACTION':
            return {
                ...state,
                results: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.payload
            }       
        case 'AUDIOVISUAL_SET_PAGINATION_TOTAL_PAGES':
            return {
                ...state,
                totalPages: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_ROWS':
            return {
                ...state,
                row: action.payload
            }
        case 'AUDIOVISUAL_SET_PAGINATION_FIRST':
            return {
                ...state,
                first: action.payload
            }
        case 'AUDIOVISUAL_SET_FILTER':
            return {
                ...state,
                filter: action.payload
            }
        case 'AUDIOVISUAL_SET_SORT':
            return {
                ...state,
                sort: action.payload
            }
        case 'AUDIOVISUAL_SET_EXPORT_EXCEL':
            return {
                ...state,
                excelExport: {
                    searchTerm: action.payload,
                    status: 'pending'
                }
            }
        default:
            return state
    }
}
