import { aggregateUpdatedAction } from './store/actions'

export const createCallbacks = (dispatch) => {

    const dispatchAggregateUpdate = (id, version) => dispatch(aggregateUpdatedAction(id, version))

    //:IReceivesNotifications
    return {
            AggregateUpdated: (id, version) => {
                dispatchAggregateUpdate(id, version)
            }

        }
}
