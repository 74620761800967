import { useDispatch } from 'react-redux'
import { removeDuplicatesTitles, removeEmptyRowsFromTitle } from 'components/Body/data-entry/AudioVisual/programmeDetailsUtils' 
import { setAreEpisodeLanguagesInvalid, setAreEpisodeTitlesInvalid, setEpisodeTitles, setEpisodeDraftResultNumberAction, setEpisodeDraftResultSeriesAction } from 'components/Body/data-entry/AudioVisual/store/actions'

// These numbers are synchronised to Database values. Beware of changing!
const programmeTypeFiction = 4
const programmeTypeDocumentary = 8
const programmeTypeEducation = 16
const programmeTypePerformance = 32
const programmeTypeNonfiction = 64

const formatTypeFeatureFilm = 1
const formatTypeSerial = 2
const formatTypeSeries = 3
const formatTypeSoapSitcom = 4
const formatTypeTvFilm = 5
const formatTypeProgramme = 6
const formatTypeReading = 7
// end of synced numbers

const defaultSeriesNumber = 0
const defaultEpisodeNumber = 0

export const validateProgrammeChanges = (toast, dispatch, resultsDataForEdit, primaryTitle, programmeTypeId, formatId, primaryCountry, episodeData, episodePrimaryTitle, episodeValidationRequired) => {
    let isValid = true;

    if (resultsDataForEdit) {
        // Program Main title should not be empty
        if (!primaryTitle || !primaryTitle.title) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Primary Title is required' })
            isValid = false
        }
        // Master IDA ID should be numeric
        if (resultsDataForEdit.idAid && isNaN(resultsDataForEdit.idAid)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Master IDA ID should be numeric' })
            isValid = false
        }
        // Programme Type should be selected
        if (!programmeTypeId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Programme Type is required' })
            isValid = false
        }
        // Format should be selected
        if (!formatId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Format is required' })
            isValid = false
        }
        else {
            if (formatId === 1 || formatId === 5) {
                if (!resultsDataForEdit.animeOrLive) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Anime/Live is required for Film Type' })
                    isValid = false
                }
            }
        }
        // Language should be selected
        if (!resultsDataForEdit.languageId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Language is required' })
            isValid = false
        }
        // Primary Country should be selected
        if (!primaryCountry || !primaryCountry.countryId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Primary Country is required' })
            isValid = false
        }

        if (episodeValidationRequired){
            isValid = isValid && validateAndSetDefaultEpisodeDetails(toast,dispatch,episodeData, resultsDataForEdit, episodePrimaryTitle)
        }
        // Perform validation in EpisodeDetails
        //if (episodeDetailsRef.current) {
        //    isValid = isValid && episodeDetailsRef.current.validateAndSetDefaultEpisodeDetails()
        //}

    }

    return isValid
}

// PH - Introduced logic to add default values to certain non-mandatory 
// fields, these are required for the trip to the server.
export const validateAndSetDefaultEpisodeDetails = (toast, dispatch, episodeData, programmeData, episodePrimaryTitle,) => {
    // Perform validation checks
    // Return true if valid, false otherwise
    const setAcceptedSeries = (series) => dispatch(setEpisodeDraftResultSeriesAction(series))
    const setAcceptedNumber = (number) => dispatch(setEpisodeDraftResultNumberAction(number))

    let isValid = true
    if (episodeData) {

        if (!episodePrimaryTitle || !episodePrimaryTitle.title) {
            if (episodeTitleIsMandatory(programmeData)) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Episode Title is Mandatory'
                })
                isValid = false
            }
        }

        if (!episodeData.number || episodeData.number <= 0) {
            if (episodeNumberIsMandatory(programmeData)) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Episode Number is Mandatory'
                })
                isValid = false
            }
            else {
                setAcceptedNumber(defaultEpisodeNumber)
            }
        }

        if (!episodeData.series || episodeData.series <= 0) {
            if (seriesNumberIsMandatory(programmeData)) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Series Number is Mandatory'
                })
                isValid = false
            }
            else {
                setAcceptedSeries(defaultSeriesNumber)
            }
        }

        if (!episodeData.durationMinutes || !episodeData.yearOfProduction) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Duration and Year Produced are Mandatory'
            })
            isValid = false
        }
        if (episodeData.durationMinutes <= 0 || episodeData.yearOfProduction <= 0) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Duration and Year Produced are Mandatory'
            })
            isValid = false
        }

        // Perform validation for titles
        if (episodeData.titles) {
            isValid = isValid && validateTitleChanges(toast, dispatch, episodeData.titles)
        }
    }
    else {
        isValid = false
    }

    return isValid
}

export const validateEpisodeListDetails = (toast, dispatch, episodeList) => {
    // Perform validation checks
    // Return true if valid, false otherwise
    let isValid = true
    Object.values(episodeList).forEach((episodeData) => {
        // Program Main title should not be empty
        if (!episodeData.title || episodeData.title == "" || episodeData.title.trim() === "") {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Title is Mandatory' })
            isValid = false
        }
        if (!episodeData.number && episodeData.number <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Number is Mandatory' })
            isValid = false
        }
        if (!episodeData.series && episodeData.series <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Series Number is Mandatory' })
            isValid = false
        }
        if (!episodeData.durationMinutes || !episodeData.yearOfProduction) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration and Year Produced are Mandatory' })
            isValid = false
        }
        if (episodeData.durationMinutes <= 0 || episodeData.yearOfProduction <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration and Year Produced are Mandatory' })
            isValid = false
        }
    })
    return isValid
}

const episodeTitleIsMandatory = (programmeData) => {
    
    if (programmeData.isRadio) {
        return radioEpisodeTitleIsMandatory(programmeData.programmeTypeId, programmeData.formatId)
    }
    return tvEpisodeTitleIsMandatory(programmeData.programmeTypeId, programmeData.formatId)
}

const tvEpisodeTitleIsMandatory = (programmeType, programmeFormat) => {
    if (
        ((programmeType === programmeTypeDocumentary) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypeFiction) && (programmeFormat === formatTypeSerial)) ||
        (programmeFormat === formatTypeSoapSitcom) ||
        ((programmeType === programmeTypeFiction) && (programmeFormat === formatTypeSeries)) ||
        ((programmeType === programmeTypeNonfiction) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypeEducation) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypePerformance) && (programmeFormat === formatTypeProgramme))) {
        return true
    }
    return false
}

const radioEpisodeTitleIsMandatory = (programmeType, programmeFormat) => {
   return true
}

const episodeNumberIsMandatory = (programmeData) => {

    if (programmeData.isRadio) {
        return radioEpisodeNumberIsMandatory(programmeData.programmeTypeId, programmeData.formatId)
    }

    return tvEpisodeNumberIsMandatory(programmeData.programmeTypeId, programmeData.formatId)
}

const tvEpisodeNumberIsMandatory = (programmeType, programmeFormat) => {
    if (
        ((programmeType === programmeTypeDocumentary) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypeFiction) && (programmeFormat === formatTypeSerial)) ||
        (programmeFormat === formatTypeSoapSitcom) ||
        ((programmeType === programmeTypeFiction) && (programmeFormat === formatTypeSeries)) ||
        ((programmeType === programmeTypeNonfiction) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypeEducation) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypePerformance) && (programmeFormat === formatTypeProgramme))) {
        return true
    }
    return false
}

const radioEpisodeNumberIsMandatory = (programmeType, programmeFormat) => {
    if (programmeFormat === formatTypeReading) {
        return false
    }
    return true
}

const seriesNumberIsMandatory = (programmeData) => {
    if (programmeData.IsRadio) {
        return radioSeriesNumberIsMandatory(programmeData.programmeTypeId, programmeData.formatId)
    }
    return tvSeriesNumberIsMandatory(programmeData.programmeTypeId, programmeData.formatId)
}

const tvSeriesNumberIsMandatory = (programmeType, programmeFormat) => {

    if (
        ((programmeType === programmeTypeDocumentary) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypeFiction) && (programmeFormat === formatTypeSerial)) ||
        (programmeFormat === formatTypeSoapSitcom) ||
        ((programmeFormat === formatTypeSeries)) ||
        ((programmeType === programmeTypeFiction) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypeEducation) && (programmeFormat === formatTypeProgramme)) ||
        ((programmeType === programmeTypePerformance) && (programmeFormat === formatTypeProgramme))) {
        return true
    }
    return false
}

const radioSeriesNumberIsMandatory = (programmeType, programmeFormat) => {
    if (programmeFormat === formatTypeReading) {
        return false
    }
    return true
}

export const validateTitleChanges = (toast, dispatch, titles) => {    
    let isValid = true
    titles = titles && titles.length > 0 && titles?.filter(title => title.titleTypeId !== 1)
    const emptyRowsRemoved = removeEmptyRowsFromTitle(titles)
    dispatch(setEpisodeTitles(emptyRowsRemoved))

    const uniqueTitles = removeDuplicatesTitles(emptyRowsRemoved)
    dispatch(setEpisodeTitles(uniqueTitles))

    // Check if all titles have title filled
    const invalidTitles = uniqueTitles.map((title, index) => {
        if (!title.title) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Title at row ${index + 1} must have both language and title filled` })
            return true
        }
        return false
    })
    dispatch(setAreEpisodeTitlesInvalid(invalidTitles))
    if (invalidTitles.some(invalid => invalid)) {
        isValid = false
    }
    // Check if all titles have both language and title filled
    const invalidLanguages = uniqueTitles.map((title, index) => {
        if (!title.languageId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Language at row ${index+1} must have both language and title filled` })
            return true
        }
        return false
    })
    dispatch(setAreEpisodeLanguagesInvalid(invalidLanguages))
    if (invalidLanguages.some(invalid => invalid)) {
        isValid = false
    }


    // If all validations pass
    return isValid
}

// returns a bool indicating whether the new format is valid
export const validateChangeProgrammeFormat = (newFormatId, programmeData) => {
    
    // We get programme data just to keep the interface clean,
    // but we only care about the episode count

    switch (newFormatId.sapCode) {
        case "FF":
        case "TF":
            // The rule is we do not allow when multiple episodes
            return (Object.keys(programmeData.episodes).length <= 1)
        default:
            return true
    }

}
