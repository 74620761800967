import { v4 as uuidv4 } from 'uuid';
//todo: delete this test file when no longer required
export const testProgrammeObject =() => {
return {
    "AnimeOrLive": "A",
    "FormatId": 1,
    "IsRadio": false,
    "LanguageId": 2,
    "ProgrammeTypeId": 2,
    "SapCode": Math.floor(Math.random()*99999999).toString().padStart(8,'0'),
    
  "Countries": [
    {
        "Id":"92827182-55A0-1F40-B84B-22302991C999",
        "CountryId":1,
        "IsPrimary":true
    },
    {
        "Id":"01829382-6610-2240-B84B-2235591FC129",
        "CountryId":4,
        "IsPrimary":false
    }
  ],
    "Titles": [
      {
        "Id": uuidv4(),
        "Title": "Test_2024_05_09",
        "LanguageId": 22,
        "TitleTypeId": 1
      }
    ]
  }
}