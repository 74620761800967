import { React, useState } from 'react'
import { AllResults, AudioVisual } from './SearchResultsPanels'
import { TabView, TabPanel } from 'primereact/tabview'
import { Badge } from 'primereact/badge'
import { selectLatestSearchTerm } from 'components/Header/GlobalSearchBar/SearchPreview/store/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { selectSearchResultsTabSelect } from 'components/Body/Search/store/selectors'
import { setSearchResultsTabSelect } from 'components/Body/Search/store/actions'
// ReSharper disable once InconsistentNaming
const SearchResults = (props) => {
    // This component now has a props.id and a props.className  
    const [activeTab, setActiveTab] = useState(5)
    const dispatch = useDispatch()
    const handleTabChange = (e) => {
        dispatch(setSearchResultsTabSelect(e.index))
    }
    const searchResultsTabSelect = useSelector(selectSearchResultsTabSelect)
    const searchTerm = useSelector(selectLatestSearchTerm)
    const tabHeaderITemplate = (options) => {
        return (
            <div class="d-flex align-items-center">
                <div id="figure"><Badge value="500" size="xlarge"></Badge></div>
                <div id="results-text" class="ml-3">
                    <p class="mb-0" style={{ fontSize: "16px", fontWeight: "600" }}>Search results for:</p>
                    <p class="mb-0" style={{ fontSize: "20px", fontWeight: "600" }}>{searchTerm}</p>
                </div>
            </div>
        )
    }

    return (
        <div className='application-content d-flex flex-column flex-grow-1' >
            <div className='container-fluid d-flex flex-column flex-grow-1'>
                <div className='row d-flex flex-grow-1'>
                    <div className='col-9 d-flex flex-column flex-grow-1'>
                        <div className='app-content flex-grow-1'>
                            <div class='row'>
                                <div class="col-12">
                                    <div class="alcs-panel">
                                        <TabView className='custom-content' activeIndex={searchResultsTabSelect} onTabChange={handleTabChange}>
                                            <TabPanel header="AV & Radio" leftIcon="fa-light fa-clapperboard-play mr-2">
                                                <p className="m-0">
                                                    <AudioVisual id={props.id + '_container_tab_div_Content_tab0_avResults'} />
                                                </p>
                                            </TabPanel>
                                            <TabPanel header="Books" leftIcon="fa-light fa-book mr-2">
                                                <p className="m-0">
                                                    Books content
                                                </p>
                                            </TabPanel>
                                            <TabPanel header="Serials" leftIcon="fa-light fa-rectangle-vertical-history mr-2">
                                                <p className="m-0">
                                                    Serials content
                                                </p>
                                            </TabPanel>
                                            <TabPanel header="Visual Arts" leftIcon="fa-light fa-palette mr-2">
                                                <p className="m-0">
                                                    Visual Arts content
                                                </p>
                                            </TabPanel>
                                            <TabPanel header="Contacts" leftIcon="fa-light fa-address-card mr-2">
                                                <p className="m-0">
                                                    Contacts content
                                                </p>
                                            </TabPanel>
                                            <TabPanel header="All Results" leftIcon="">
                                                <p className="m-0">
                                                    <AllResults id={props.id + '_container_tab_div_Content_tab0_AllResults'} />
                                                </p>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SearchResults
