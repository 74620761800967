import React from 'react'
import { Button } from 'primereact/button'
import { formatDateMonthTime } from 'modules'

// ReSharper disable once InconsistentNaming
export const EpisodeDetailsHeader = ({ resultsDataForEdit }) => {
    return (
        <div className='details-dashboard col-12'>
            <div className='details-dashboard-cpt text-center col-1 mt-1'>
                <span className='details-dashboard-label mt-4'>Total Episodes</span>
                <span className='details-dashboard-data-lg t-1'>{resultsDataForEdit && resultsDataForEdit.episodes && Object.keys(resultsDataForEdit.episodes).length}</span>
            </div>            
            <div className='details-dashboard-cpt col-2 offset-6'>
                <span className="title lh-lg mb-1">History</span>
                <ul>
                    <li>
                        <span className="details-dashboard-label-in">Created by:</span>
                        <span className="details-dashboard-data-in">{resultsDataForEdit && resultsDataForEdit.createdBy}</span>
                    </li><li>
                        <span className="details-dashboard-label-in">Date Created:</span>
                        <span className="details-dashboard-data-in">{resultsDataForEdit && formatDateMonthTime(resultsDataForEdit.createdOn)}</span>
                    </li><li>
                        <span className="details-dashboard-label-in">Modified by:</span>
                        <span className="details-dashboard-data-in">{resultsDataForEdit && resultsDataForEdit.updatedBy}</span>
                    </li><li>
                        <span className="details-dashboard-label-in">Last Modified:</span>
                        <span className="details-dashboard-data-in">{resultsDataForEdit && formatDateMonthTime(resultsDataForEdit.updatedOn)}</span>
                    </li>
                </ul>
            </div>
            <div className='details-dashboard-cpt text-center col-2'>
                <br />
                <br />
                <Button label="View History" icon="fa-solid fa-clock-rotate-left" className="ml-3" raised />
            </div>
        </div>
    )
}