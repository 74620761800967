import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchModeAction } from './store'
import { selectSearchModeId, selectSearchModes, selectAuthenticatedDepartments } from './store/selectors'
import 'primeicons/primeicons.css'

// ReSharper disable once InconsistentNaming
const SearchModeDropDown = (props) => {
    const dispatch = useDispatch()  
    const setSearchMode = (modeId) => dispatch(setSearchModeAction(modeId))

    const searchMode = useSelector(selectSearchModeId)
    const searchModes = useSelector(selectSearchModes)
    const departments = useSelector(selectAuthenticatedDepartments)

    const selectedMode = searchMode === 0 ? null : searchMode
    if (searchMode === null) {
        const dept = (departments?.length ?? 0) === 0 ? 'Default View' : departments[0].Name

        const selectedSearchMode = searchModes.find(m => m.name === dept)
        setSearchMode(selectedSearchMode?.id || 0)
    }

    const views = [
        { name: 'fa-light fa-clapperboard-play', id: 1 },
        { name: 'fa-light fa-user-group', id: 2 },
        { name: 'fa-light fa-book', id: 3 },
        { name: 'fa-light fa-coins', id: 4 }
    ]

    function dropdownOptionTemplate(option) {
        if (!option) return dropdownOptionTemplate(defaultOption)
        const view = views.find(v => v.id === option.id)
        const iconClass = view ? view.name : 'pi pi-eye'
        const html = (
            <span><i className={iconClass}></i>{option.name}</span>
            )
        return html
    }


    const dropdownOptions = searchModes.filter(mode => {
        return mode.id !== 0
    }).map(mode => ({ ...mode, display: dropdownOptionTemplate(mode) }))

    const defaultOption = searchMode == null ? dropdownOptionTemplate(searchModes[0]) : dropdownOptionTemplate(searchModes[searchMode])

    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value
        setSearchMode(selectedValue?.id || 0)
    }

    return (
        <span id={props.id} className={props.className}>
            <Dropdown
                id={props.id + '_dropdown'}
                value={selectedMode}
                onChange={handleDropdownChange}
                options={dropdownOptions}
                optionLabel="display"
                optionValue="id" 
                placeholder={defaultOption}
                showClear
                dropdownIcon="pi pi-angle-down"
                className="grey"
                panelClassName="grey"
            />
        </span>
    )
}

export default SearchModeDropDown
