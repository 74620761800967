
/* Audio Visual */
export const setTvFilmRadioFetchStatusAction = (status) => {
    return { type: 'PREVIEW_AUDIOVISUAL_SET_FETCH_STATUS_ACTION', payload: status }
}
export const setTvFilmRadioFetchResultsAction = (results) => {
    return { type: 'PREVIEW_AUDIOVISUAL_SET_FETCH_RESULTS_ACTION', payload: results }
}
export const setSearchResultsTabSelect = (tabNumber) => ({
        type: 'SetSearchResultsTabSelect',
        payload: tabNumber
})
export const setPanelVisibilityAction = (isVisible) => {
    return {
        type: 'SET_PANEL_VISIBILITY',
        payload: isVisible
    }
}