import GlobalSearchBar from './GlobalSearchBar'
import SiteLogo from './SiteLogo'
import { headerReducer } from './store'
import { ActionSetLeft } from './ActionSetLeft'
import { ActionSetRight } from './ActionSetRight'
import SecondaryNavigation from './SecondaryNavigation'

const siteLogoClass = 'col-1 d-flex justify-content-center'
const actionSetLeftClass = 'col-2 row d-flex justify-content-center'
const actionSetRightClass = 'col-3 row d-flex justify-content-center'
const globalSearchClass = 'col-5'


// ReSharper disable once InconsistentNaming
const Header = (props) => {
    console.log('Header props', props)
    return (
        <div id="header">
            <div className='top-header'>
                <div className='row'>
                    <SiteLogo id={'brand-container'} className={siteLogoClass} />
                    <ActionSetLeft id={'top-cta-left'} className={actionSetLeftClass} />
                    <GlobalSearchBar id={'header-search'} className={globalSearchClass} />
                    <ActionSetRight id={'top-cta-right'} className={actionSetRightClass} />                    
                </div>
            </div>
            <div id={props.id} className="row">
                <SecondaryNavigation id={props.id + '_SecondaryNavigation'} />
            </div>
        </ div>
    )
}

export { headerReducer }

export default Header