import { authFetch } from '../auth/authFetch'
export async function fileDownload(url, fileName) {
    try {
        const response = await authFetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": 'application/json'
            }
        })
        if (!response.ok) {
            throw new Error('Error during network call')
        }
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
    } catch (error) {
        console.error('There was an error downloading the file:', error);
    }
}

export default fileDownload
