import { selectBodySearchAppSettings,selectBodySearchApp } from 'components/Body/Search/store/selectors'
import { createSelector } from 'reselect';
import { scribeUrlType, searchMode } from 'modules/enums'
import { urlBuilder } from 'modules'
import { selectSearchTerm } from 'components/Header/GlobalSearchBar/SearchPreview/store/selectors'
import { selectAvApiHost } from 'globalSelectors' 

const showState = (state, name) => {
    console.log(name, state)
    return state
}

export const selectBodySearchResultsPanelsSettings = (store) => selectBodySearchAppSettings(store).SearchResultsPanels
export const selectBodySearchResultsPanels = (store) => selectBodySearchApp(store).searchResultsPanels
export const selectBodySearchResultsPanelsResults = (store) => selectBodySearchResultsPanels(store).searchResults

const searchUrlBuilder = (searchTerm, searchMode) => urlBuilder(scribeUrlType.Search, searchTerm, searchMode)
const fetchRequest = (store, count, page, filter, sort, term, url) => ({ count,page, filter, sort, term, url, status: store.status })

/*AudioVisual */
export const selectAvTabLink = createSelector(
    [selectSearchTerm],
    searchTerm => searchUrlBuilder(searchTerm, searchMode.TvFilmRadio)
)

export const selectTvFilmRadioUrl = createSelector(
    [selectAvApiHost],
    host => `${host}/search`
)

export const selectAvCurrentPage = createSelector(
    [selectBodySearchResultsPanelsResults],
    (searchResults) => searchResults.avCurrentPage
)

export const selectAvTotalPages = createSelector(
    [selectBodySearchResultsPanelsResults],
    (searchResults) => searchResults.avTotalPages
)

export const selectTvFilmRadioAvRows = createSelector(
    [selectBodySearchResultsPanelsResults],
    (av) => av.avRow
)

export const selectTvFilmRadioAvFirst = createSelector(
    [selectBodySearchResultsPanelsResults],
    (av) => av.avFirst
)

export const selectAvFilter = createSelector(
    [selectBodySearchResultsPanelsResults],
    (searchResults) => searchResults.avFilter
)

export const selectAvSort = createSelector(
    [selectBodySearchResultsPanelsResults],
    (searchResults) => searchResults.avSort
)

export const selectTvFilmRadioFetchRequest = createSelector(
    [selectBodySearchResultsPanelsResults, selectTvFilmRadioAvRows, selectAvCurrentPage, selectAvFilter, selectAvSort, selectSearchTerm, selectTvFilmRadioUrl],
    fetchRequest
)

export const selectTvFilmRadioFetchResults = createSelector(
    [selectBodySearchResultsPanelsResults],
    (av) => av.results
)

export const selectTvFilmRadioFetchStatus = createSelector(
    [selectBodySearchResultsPanelsResults],
    (av) => av.status
)


   

