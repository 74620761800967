import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'

const fetchData = async (count, page, filter, sort, endpoint, controller) => {

    if (count === 0) {
        return []
    }
    if (page === 0) {
        return[]
    }

    const queryParamsObject = {
        pageNumber: page.toString(),
        count: count.toString()
    }

    if (filter) {
        queryParamsObject.filter = filter.toString()
    }

    if (sort) {
        queryParamsObject.sortBy = sort.toString()
    }

    const queryParams = new URLSearchParams(queryParamsObject).toString()
    const url = `${endpoint}/GetAll?${queryParams}`;

    let responseCode = -1

    try {

        const response = await authFetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": 'application/json'
            },
            signal: controller.signal
        })

        const wasAborted = controller?.signal?.aborted

        controller = null

        if (wasAborted) {
            return null
        }

        responseCode = response.status

        if (responseCode === 200) {
            const json = await response.json()
            return json
        }

        throw {
            Message: 'The AV Landing fetch request was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    }
    catch (error) {
        throw {
            Message: 'The AV landing fetch request was not successful',
            StatusCode: responseCode,
            Details: error
        }
    }
}

const setUpFetch = (count, page, filter, sort, endpoint) => {
    const controller = new AbortController()
    return  {
            controller,
        fetch: () => fetchData(count, page, filter, sort, endpoint, controller)
        }
}

// ReSharper disable once InconsistentNaming
const AudioVisualLandingApi = (props) => {

    const [abortController, setAbortController] = useState(null)
    const [oPage, setOPage] = useState(-1)
    const [oSort, setOSort] = useState(null)
    const [oFilter, setOFilter] = useState(null)

    const setStatusAction = props.setStatusAction
    const setResultsAction = props.setResultsAction
    const requestSelector = props.requestSelector

    /* Dispatch */
    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setResults = (results) => dispatch(setResultsAction(results))

    /* Selectors */
    const apiRequest = useSelector(requestSelector)

    const { count, page, filter, sort, url, status } = apiRequest

    if (oPage === page && oFilter === filter && oSort === sort) {
        return undefined
    }

    setOPage(page)
    setOFilter(filter)
    setOSort(sort)

    if (status === 'pending' && abortController) {
        abortController.abort()
    }

    setStatus({ status: 'pending' })

    var { controller, fetch } = setUpFetch(count, page, filter, sort, url)

    setAbortController(controller)
    fetch()
        .then(results => {
            if (results) {
                setResults(results)
                setStatus({ status: 'complete' })
            }
            //else was aborted
        })
        .catch(error => {
            setResults({})
            setStatus({ status: 'error', error: error })
        })

    return undefined
}

export default AudioVisualLandingApi
