const initialState = {
        isAuthenticated: false,
        departments: [],
        scopes: []
    }

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case '_Authentication_SetAuthenticated':
            return {
                    ...state,
                    ...action.payload,
                    error: undefined
                }
        case '_Authentication_SetError':
            return {
                    ...state,
                    error: action.payload
                }
        default:
            return state
    }
}

