// ReSharper disable once InconsistentNaming

// Escape special characters in the search term
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// ReSharper disable once InconsistentNaming
const HighlightText = ({ text, searchTerm }) => {
    if (text && searchTerm) {
        const escapedSearchTerm = escapeRegExp(searchTerm);
        const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'));
        return (
            <span>
                {parts.map((part, i) =>
                    part.toLowerCase() === searchTerm.toLowerCase()
                        ? <span key={i} style={{ backgroundColor: '#fed200' }}>{part}</span>
                        : <span key={i}>{part}</span>
                )}
            </span>
        );
    } else {
        return <span>{text}</span>;
    }
}
export default HighlightText

