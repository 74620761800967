import { React } from 'react';
import { useSelector } from 'react-redux'
import BookListPreview from 'components/Header/GlobalSearchBar/SearchPreview/BookListPreview';
import ContactListPreview from 'components/Header/GlobalSearchBar/SearchPreview/ContactListPreview';
import SerialListPreview from 'components/Header/GlobalSearchBar/SearchPreview/SerialListPreview';
import VisualArtsListPreview from 'components/Header/GlobalSearchBar/SearchPreview/VisualArtsListPreview';
import AudioVisualResultsList from './AudioVisualResultsList';
import { selectLatestSearchTerm } from 'components/Header/GlobalSearchBar/SearchPreview/store/selectors'

// ReSharper disable once InconsistentNaming
export function AllResults(props) {
    // This component now has a props.id and a props.className
    const searchTerm = useSelector(selectLatestSearchTerm)
    //const searchMode = searchMode.Default //always default  
    return (
        /*<SearchResultsPreview id={props.id + '_allResults'} className={'allResults_tab'}  />*/
        <div id={props.id}
            className={props.className} >
            <div id={props.id + '_container'} className={props.className + ' container'}>
                <div id={props.id + '_container_row'} className={'row row-cols-2'}>
                    <div id={props.id + '_container_row_left'} className={'col'}>
                        <div id={props.id + '_left_container'} className={'container'} >
                            {/*Use BookResultsList component in future implementation*/}
                            <div class="mb-4">
                                <BookListPreview  id={props.id + '_left_BooksPreview'} />
                            </div>
                            {/*Use ContactsResultsList component in future implementation*/}
                            <div class="mb-4">
                                <ContactListPreview  id={props.id + '_left_ContactsPreview'} />
                            </div>
                            {/*Use SerialResultsList component in future implementation*/}
                            <div class="mb-4">
                                <SerialListPreview  id={props.id + '_left_SerialPreview'} />
                            </div>
                        </div>
                    </div>
                    <div id={props.id + '_container_row_right'} className={'col'}>
                        <div id={props.id + '_right_container'} className={'container'} >
                            <div class="mb-4">
                                <AudioVisualResultsList id={props.id + '_right_AvPreview'} searchTerm={searchTerm} height="500px" />
                            </div>
                            {/*Use VisualArtsResultsList component in future implementation*/}
                            <div class="mb-4">
                                <VisualArtsListPreview id={props.id + '_right_VaPreview'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


