import React, { useState } from "react";
import NotificationBell from './NotificationBell'
import SearchModeDropDown from './SearchModeDropDown'
import AboutPanel from './AboutPanel'
import BurgerMenu from './BurgerMenu'
export function ActionSetRight(props) {
    const logOutClass = 'ml-2 mb-4 mr-2 float-end' //note that we need to replace the 'log out' button with a burger menu component later

    
    return (
        <div id='top-cta-right' className='col-auto d-flex justify-content-center top-cta-right'>            
            <SearchModeDropDown id={props.id + '_SearchMode'} className="ml-3 " raised />
            <NotificationBell id={props.id + '_NotificationButton'} />
            <AboutPanel id={props.id + '_About'} />
            <BurgerMenu id={props.id + '_Burger'} className={logOutClass} />
        </div>
    )
}

