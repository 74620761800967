import { createSelector } from 'reselect';
import { selectBodySearchResultsPanelsSettings ,selectBodySearchResultsPanels} from 'components/Body/Search/SearchResultsPanels/store/selectors'
import { scribeUrlType, searchMode } from 'modules/enums'
import { urlBuilder } from 'modules'
import { selectAvApiHost } from 'globalSelectors'
import { selectSearchTerm } from 'components/Header/GlobalSearchBar/SearchPreview/store/selectors'

const showState = (state, name) => {
    console.log(name, state)
    return state
}
export const selectBodyAllResultsSettings = (store) => selectBodySearchResultsPanelsSettings(store).AllResults
export const selectBodyAllResults= (store) => selectBodySearchResultsPanels(store).allResults

export const selectAudioVisual = state => showState(selectBodyAllResults(state).audioVisual, 'audioVisual')

const searchUrlBuilder = (searchTerm, searchMode) => urlBuilder(scribeUrlType.Search, searchTerm, searchMode)
const fetchRequest = (store, count,page, term, url) => ({ count,page, term, url, status: store.status })


/*AudioVisual */
export const selectAvTabLink = createSelector(
    [selectSearchTerm],
    searchTerm => searchUrlBuilder(searchTerm, searchMode.TvFilmRadio)
)

export const selectTvFilmRadioUrl = createSelector(
    [selectAvApiHost],
    host => `${host}/search`
)
export const selectAudioVisualResultsListCount = createSelector(
    [selectBodyAllResultsSettings],
    (countSettings) => countSettings.AudioVisualResultsList
)
export const selectAudioVisualResultsPage= createSelector(
    [],
    () => 1
)
export const selectTvFilmRadioFetchRequest = createSelector(
    [selectAudioVisual, selectAudioVisualResultsListCount, selectAudioVisualResultsPage, selectSearchTerm, selectTvFilmRadioUrl],
    fetchRequest
)
export const selectTvFilmRadioFetchResults = createSelector(
    [selectAudioVisual],
    (av) => av.results
)