import React from 'react';

import { useParams } from 'react-router-dom';

export function DistributionDetails(props) {
    // This component now has a props.id and a props.className

    const params = useParams()
    const id = params.id || '*new*'

    return (
        <p>
            Distribution Reference: {id}
        </p>
)
}
