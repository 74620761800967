import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import edit from 'assets/images/edit.svg'
import { InputNumber } from 'primereact/inputnumber'
import { FilterMatchMode } from 'primereact/api'
import { Calendar } from 'primereact/calendar'

// ReSharper disable once InconsistentNaming
const CommonGrid = (props) => {
    // Deconstruct props to get specific component related selectors and actions
    const {
        dataKey,
        totalCount,
        emptyMessage,
        columnDefinitions,
        selectTotalPages,
        selectFirst,
        selectRows,
        fetchStatusSelector,
        setTotalPagesAction,
        setCurrentPageAction,
        setFirstAction,
        setRowsAction,
        setFilterAction,
        setSortAction,
        selectionMode,
        selectionStyle,
        scrollHeight,
        allowRowEdits,
        header
    } = props
    const [validationErrors, setValidationErrors] = useState({});
    const [expandedRows, setExpandedRows] = useState(null)
    const [multiSortMeta, setMultiSortMeta] = useState([])
    const [filters, setFilters] = useState(null);
    const [lazyState, setlazyState] = useState({
        filters: {
            Id: { value: null, matchMode: FilterMatchMode.EQUALS},
            series: { value: null, matchMode: FilterMatchMode.EQUALS },
            year: { value: null, matchMode: FilterMatchMode.EQUALS },
            ida: { value: null, matchMode: FilterMatchMode.EQUALS },
            updatedOn: { value: null, matchMode: FilterMatchMode.DATE_IS }
        }
    })
    
    const defaultRowsPerPage = 100

    // Use specific domain related selectors and actions
    const first = useSelector(selectFirst)
    const rows = useSelector(selectRows)
    const fetchStatusSelect = useSelector(fetchStatusSelector)
    const fetchStatus = fetchStatusSelect['status']
    const dispatch = useDispatch()

    // Calculate total pages
    const pagesCount = totalCount > 0 ? Math.ceil(totalCount / 10) : 0
    const selectPageCount = useSelector(selectTotalPages)

    // Dispatch action to set total pages if it's different
    if (selectPageCount !== pagesCount) {
        dispatch(setTotalPagesAction(pagesCount))
    }

    // Function to apply custom class to expanded rows
    const rowClassName = (rowData) => {
        return {
            'expanded-row': expandedRows && expandedRows.some(expRow => expRow.id === rowData.id)
        }
    }

    useEffect(() => {
        const lazyLoadEventData = { rows, first, multiSortMeta, filters }
        lazyLoadData(lazyLoadEventData)
    }, [])    

    // Lazy load data function
    const lazyLoadData = (event) => {
        setlazyState(event);
        const currentPage = Math.floor(event.first / event.rows) + 1
        const sortFields = event.multiSortMeta
            .map(sort => `${sort.field} ${sort.order === 1 ? 'asc' : 'desc'}`)
            .join(',')

        setFilters(event.filters)

        const filter = event.filters ? Object.entries(event.filters)
            .flatMap(([field, fieldFilter]) => {
                return fieldFilter.constraints.map(constraint => {
                    let value = constraint.value;
                    if (value) {
                        
                        if (constraint.matchMode === FilterMatchMode.DATE_IS || constraint.matchMode === FilterMatchMode.DATE_IS_NOT || constraint.matchMode === FilterMatchMode.DATE_BEFORE|| constraint.matchMode === FilterMatchMode.DATE_AFTER) { 
                            value = value.toISOString();
                        }
                        return `${field} ${constraint.matchMode} ${value}`;
                    }
                    return null
                });
            })
            .filter(condition => condition !== null)
            .join(' and ') : null

        setMultiSortMeta(event.multiSortMeta)
        dispatch(setCurrentPageAction(currentPage))
        dispatch(setFirstAction(event.first))
        dispatch(setRowsAction(event.rows))
        dispatch(setSortAction(sortFields)) 
        dispatch(setFilterAction(filter)) 

    }
     

    // ReSharper disable once InconsistentNaming
    
    const paginatorPosition = 'bottom'
    const paginatorTemplate =
        /*' RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageInput'*/
        'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'

    const rowsPerPageOptions = [10, 25, 50, defaultRowsPerPage ]

    // Should not need to modify anything after this point
    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0
    }

    const displayActionsTemplate = function (rowData) {
        return (
            <div>
                <img src={edit} width="16" alt="" />
            </div>
        )
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div id={props.id + '_container_dataTable_nestedTable'} className="p-3">
                <DataTable value={data.contributions} scrollable scrollHeight={`${scrollHeight ?? 430}px`}>                
                    <Column field="name" header="Contributor Name"></Column>
                    <Column field="authorKey" header="Author Key"></Column>
                    <Column field="contributionType" header="Contribution"></Column>
                    <Column field="percentage" header="Percent (%)"></Column>
                    <Column field="minutes" header="Minutes Worked"></Column>
                    <Column field="ipiNumber" header="IPI No"></Column>
                    <Column field="noneyet" header="Member Type"></Column>
                </DataTable>
            </div>
        )
    }
    const onRowExpand = (event) => {

    }

    const onRowCollapse = (event) => {

    }       

    const numericFilterTemplate = (options, field) => {
        const filterOptions = options[field + 'Options'];
        return (
            <InputNumber
                value={options.value}
                options={filterOptions}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={`Search by ${field}`}
                useGrouping={false}
            />
        );
    };

    const dateBodyTemplate = (rowData) => {
        return rowData.updatedOn?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) || ''
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />
    }

    const calendarEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                showTime={false}
                dateFormat="dd/mm/yy"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const createColumn = function (title, mapsToField, filter, expand, actions, template, sortable, filterMatchMode, editable) {
        // This is horrible code, but any appearance of the word "filter" in the
        // (PR) element below causes PR to draw a filter (even filter=false) so the code has to be divergent.
        // So. just because logic exists, we hive this to a function of its own.
        // Note that the same will probably be true of the sortable property, although currently everything is sortable.

        // NB - to have the grid just display text in the cell, we just *don't pass* the "template"
        // variable. Here, that'll set it to "undefined", which tricks the grid into falling back to its
        // default rendering mechanism (just to write out the contents of the cell).

        const filterAttributes = {
            filter,
            filterPlaceholder: `Search by  ${title}`
        }

        const expandAttributes = {
            expander: { allowExpansion }
        }
        const sortAttributes = {
            sortable
        }
        if (sortable) {
            sortAttributes.required = true
            sortAttributes.disabled = false
        }
        else {
            sortAttributes.required = false
            sortAttributes.disabled = true
        }
        if (filter) {
            filterAttributes.required = true
            filterAttributes.disabled = false
        }
        else {
            filterAttributes.required = false
            filterAttributes.disabled = true
        }
        if (expand) {
            expandAttributes.required = true
            expandAttributes.disabled = false
        }
        else {
            expandAttributes.required = false
            expandAttributes.disabled = true
        }
        if (expand) {
            return (
                <Column
                    style={{ width: '8rem' }}
                    {...expandAttributes}
                    header={title}
                >
                </Column>
            )
        }
        else {
            if (filterMatchMode === FilterMatchMode.EQUALS) {
                    return (
                        <Column
                            {...sortAttributes}
                            key={mapsToField}
                            field={mapsToField}
                            header={title}
                            dataType="numeric"
                            body={template}//{(rowData) => numericBodyTemplate(rowData, mapsToField)}  //TODO- find any specific reason to use numericBodyTemplate
                            filter={(options) => numericFilterTemplate(options, mapsToField)}
                            {...filterAttributes}
                        />
                    )
                }
            }

            if (filterMatchMode === FilterMatchMode.DATE_IS) {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="date"
                        body={(rowData) => dateBodyTemplate(rowData, mapsToField)}
                        editor={editable ? (options) => calendarEditor(options) : null}
                        filterElement={(options) => dateFilterTemplate(options)}
                        {...filterAttributes}
                    />
                )
            }

            if (actions) {
                return (
                    <Column
                        body={template}
                        style={{ textAlign: 'left', borderLeft: 'none' }}
                        colSpan={2}
                    />
                )
            }

            return (
                <Column
                    {...sortAttributes}
                    key={mapsToField}
                    field={mapsToField}
                    header={title}
                    body={template}
                    {...filterAttributes}
                />
            )
        
    }

    return (
        <div id={props.id + '_container'}>
            
            <DataTable
                id={props.id + '_container_dataTable'}
                value={props.data}
                size="small"
                emptyMessage={emptyMessage}
                scrollable
                scrollHeight={scrollHeight ?? 'calc(100vh - 500px)'}
                selectionMode={selectionStyle === "checkbox" ? "checkbox" : "single"}
                showGridlines
                stripedRows
                lazy
                paginator
                removableSort
                filters={filters}
                paginatorPosition={paginatorPosition}
                paginatorTemplate={paginatorTemplate}
                className="datatable-responsive"
                dataKey={dataKey}
                rows={rows}
                first={first}
                totalRecords={totalCount}
                header={header}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                onRowExpand={onRowExpand}
                onRowCollapse={onRowCollapse}
                rowExpansionTemplate={rowExpansionTemplate}
                rowsPerPageOptions={rowsPerPageOptions}
                collapsedRowIcon="pi pi-users" //"fa-light fa-chevron-up"  
                expandedRowIcon={row =>
                    <i className={`pi pi-users ${expandedRows && expandedRows.includes(row) ? 'custom-expanded' : ''}`} />
                }
                onPage={e => lazyLoadData(e)}
                multiSortMeta={multiSortMeta}
                sortMode="multiple"
                editMode="row"
                onSort={e => lazyLoadData(e)}
                onFilter={e => lazyLoadData(e)}
                loading={fetchStatus === 'pending'}
            >
                {selectionMode === "multiple" && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
                {columnDefinitions.filter(col => !col.actions).map((col) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.expand, col.actions, col.template, col.sortable, col.filterMatchMode, col.editable)
                )}
                {allowRowEdits && <Column header="Actions" rowEditor={true} bodyStyle={{ display: 'flex', justifyContent: 'flex-end', borderRight: 'none', height: '100%' }} >
                </Column>}
                {columnDefinitions.filter(col => col.actions).map((col) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.expand, col.actions, col.template, col.sortable, col.filterMatchMode))}
            </DataTable>
        </div>
    )

}

export default CommonGrid
