import React, { Component } from 'react';
import { connect } from 'react-redux' 
import { setBreadCrumbStack } from 'components/Header/SecondaryNavigation/store/actions'

class Home extends Component {
    static displayName = Home.name;

    componentDidMount() {
        this.props.dispatch(setBreadCrumbStack([{ label: '', path: '/' }]))
    }

    render() {
        return (
                <div>
          <h1>Welcome to ALCS Scribe</h1>
                <p>This is a temporary landing page</p>
      </div>
            )
    }
}

export default connect()(Home)
