import { React } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAudiovisualFetchResults, selectAudiovisualFetchRequest, selectAudiovisualCurrentPage, selectAudiovisualTotalPages, selectAudiovisualRows, selectAudiovisualFirst, selectAudiovisualFetchStatus, selectAudiovisualFilter, selectAudiovisualSort } from './store/selectors'
import { setAudiovisualFetchStatusAction, setAudiovisualFetchResultsAction, setAudiovisualTotalPagesAction, setAudiovisualFirstAction, setAudiovisualRowsAction, setAudiovisualCurrentPageAction, setAudiovisualExcelExportAction, setAudiovisualFilterAction, setAudiovisualSortAction } from './store/actions'
import { selectAvApiHost } from 'globalSelectors' 
import CommonGrid from 'components/common/CommonGrid'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button';
import { push } from 'redux-first-history';
import { FilterMatchMode } from 'primereact/api'
import AudioVisualLandingApi from 'api/DataEntry/Landing/AudioVisual'
import { Badge } from 'primereact/badge'
import { fileDownload } from 'api/FileDownload/fileDownload'

const excelDownloadPathSuffix = '/search/excelexport?searchTerm='
const excelDownloadName = 'AudioVisual.xlsx'
export function AudioVisualContent(props) {
    const avResults = useSelector(selectAudiovisualFetchResults)
    const currentPage = useSelector(selectAudiovisualCurrentPage)
    const first = useSelector(selectAudiovisualFirst)
    const rows = useSelector(selectAudiovisualRows)
    const filter = useSelector(selectAudiovisualFilter)
    const sort = useSelector(selectAudiovisualSort)
    const originalQuery = useSelector(selectAudiovisualFetchRequest)
    const avApiHost = useSelector(selectAvApiHost)

    const dispatch = useDispatch()

    const excelDownloadPath =avApiHost + excelDownloadPathSuffix + originalQuery.term
    let avResultList = []
    let avResultsCount = 0

    if (avResults) {
        avResultList = avResults['results']
        avResultsCount = avResults['total']

    }

    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }

    const handleEpisodeNavigation = (rowData) => {
        navigateTo(`/AudioVisual/${rowData.programmeId}/${rowData.episodeId}`)
    }

    const header = (
        <div>
            <div className="row d-flex justify-content-flexend">
                <div className="col-auto">
                    <Button
                        id={props.id + '_container_av_addProgramme'}
                        label="Add Programme"
                        icon="fa-light fa-plus"
                    >
                    </Button>
                </div>
                <div className="col mt-1">
                    <Badge value={`Displaying ${first + 1}-${Math.min(first + rows, avResultsCount)}`}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>of</b> </span>
                    <Badge value={avResultsCount}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>Results</b> </span>
                </div>

                <div className="col-auto">
                    <Button
                        id={props.id + '_container_av_export_excel'}
                        label="Export"
                        icon="fa-light fa-file-import"
                        onClick={() => fileDownload(excelDownloadPath, excelDownloadName)}
                    >
                    </Button>
                </div>
            </div>
        </div>
    );

    const displayEpisode = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <div className="d-flex ml-auto">
                    <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    <Button className="white ml-2" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleEpisodeNavigation(rowData)} />
                </div>
            </div>
        )
    }

    const displayEpisodeTitle = function (rowData) {
        return displayEpisode(rowData, 'episodeTitle')
    }

    const displayEpisodeCode = function (rowData) {
        return displayEpisode(rowData, 'episodeCode')
    }


    const displayProgramme = function (rowData, field) {

        const handleProgrammeNavigation = () => {
            navigateTo(`/AudioVisual/${rowData.programmeId}`)
        }
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <div className="d-flex ml-auto">
                    <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    <Button className="white ml-1" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleProgrammeNavigation()} />
                </div>               
            </div>
        )
    }

    const displayProgrammeTitle = function (rowData) {
        return displayProgramme(rowData, 'programmeTitle')
    }

    const displayProgrammeCode = function (rowData) {
        return displayProgramme(rowData, 'programmeCode')
    }

    const displayIdaCode = function (rowData) {
        return displayIda(rowData, 'episodeIda')
    }
    const displayIda = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                {rowData[field] && (
                    <div className="d-flex ml-auto">
                        <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    </div>
                )}
            </div>
        )

    }

    const avColumnDefinitions = [
        { title: 'Programme Code', mapsToField: 'programmeCode', filter: true, template: displayProgrammeCode, sortable: true },
        { title: 'Programme Title', mapsToField: 'programmeTitle', template: displayProgrammeTitle, filter: true, sortable: true },
        { title: 'Episode Code', mapsToField: 'episodeCode', filter: true, template: displayEpisodeCode, sortable: true },
        { title: 'Episode Title', mapsToField: 'episodeTitle', filter: true, template: displayEpisodeTitle, sortable: true },
        { title: 'Episode No', mapsToField: 'episodeNumber', filter: true, sortable: true },
        { title: 'Series No', mapsToField: 'episodeSeries', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'Year Produced', mapsToField: 'episodeYear', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'IDA No.', mapsToField: 'episodeIda', template: displayIdaCode, filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'Updated by', mapsToField: 'updatedBy', filter: true, sortable: true },
        { title: 'Updated Date', mapsToField: 'updatedOn', filterMatchMode: FilterMatchMode.DATE_IS, filter: true, sortable: true },
        { actions: true }
    ]

    const avResultListWithFormattedDate = avResultList?.map(result => ({
        ...result,
        updatedOn: new Date(result.updatedOn)
    })) || [];

    return (
        <div id={props.id + '_avList'} >
            <AudioVisualLandingApi
                id={props.id + '_container_results_AudioVisualLanding'}
                key={currentPage + selectAudiovisualRows}
                setStatusAction={setAudiovisualFetchStatusAction} // Use the provided action for fetching results
                setResultsAction={setAudiovisualFetchResultsAction}
                requestSelector={selectAudiovisualFetchRequest}
            />
            <CommonGrid
                id={props.id + '_avList_commonGrid'}
                keyColumnName="id"
                header={header}
                data={avResultListWithFormattedDate}
                totalCount={avResultsCount}
                emptyMessage="No Results"
                columnDefinitions={avColumnDefinitions}
                selectTotalPages={selectAudiovisualTotalPages}
                selectFirst={selectAudiovisualFirst}
                selectRows={selectAudiovisualRows}
                fetchStatusSelector={selectAudiovisualFetchStatus}
                setTotalPagesAction={setAudiovisualTotalPagesAction}
                setCurrentPageAction={setAudiovisualCurrentPageAction}
                setFirstAction={setAudiovisualFirstAction}
                setRowsAction={setAudiovisualRowsAction}
                setFilterAction={setAudiovisualFilterAction}
                setSortAction={setAudiovisualSortAction}
                currentPage={currentPage}
                filter={filter}
                sort={sort}
                selectionMode="multiple"
                allowRowEdits={true}
                selectionStyle="checkbox"
                excelDownloadPath={excelDownloadPath}
                excelDownloadName={excelDownloadName}
            />

        </div>
    )
}
