import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollPanel } from 'primereact/scrollpanel'
import { Link } from 'react-router-dom'
import { DataView } from 'primereact/dataview'

import { setSearchResultsTabSelect } from './store/actions'
import { selectBooksTabLink } from './store/selectors'
/*import { fetchDataBySearchAsync } from 'api/Books'*/
import { searchResultsTab } from 'modules/enums'
import { booksTemplate } from '../../../common/PreviewTemplate'


// ReSharper disable once InconsistentNaming

function BookListPreview(props) {
    /* Dispatch */
    const dispatch = useDispatch()
    const setBooksTab = () => dispatch(setSearchResultsTabSelect(searchResultsTab.Books))

    /* Selectors */
    const booksTabLink = useSelector(selectBooksTabLink)

    /* Render */

    let bookResultList = []
    let bookSearchResultsCount = 0


    return (
        <div class="alcs-inner-panel medium-grey">
            <div class="header"><span class="title">Books</span></div>
            <div class="content">
                <ScrollPanel id={props.id + '_scrollPanel'} style={{ width: props.width, height: props.height }}>
                    <b id={props.id + '_bookList_title'}>Books
                        <button id={props.id + '_bookList_title_button'}>{bookSearchResultsCount} results</button>
                    </b>
                    <br />
                    <div id={props.id + '_bookList_tableContainer'} className={'table-responsive'}>
                        {bookResultList && bookResultList.length > 0 ? (
                            <table id={props.id + '_bookList_tableContainer_table'} class="table">
                                <thead id={props.id + '_bookList_tableContainer_table_header'}>
                                    {/* <!-- Table header goes here -->*/}
                                </thead>
                                <tbody id={props.id + '_bookList_tableContainer_table_body'} >
                                    {/*<!-- Use a loop to iterate through bookResultList and generate rows with booksTemplate -->*/}
                                    {bookResultList.map((item, index) => (
                                        <tr id={props.id + '_bookList_tableContainer_table_body_row_' + index}>
                                            {/* <!-- Render data using booksTemplate -->*/}
                                            {booksTemplate(item, props.id + '_bookList_tableContainer_table_body_row_' + index)}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p id={props.id + '_bookList_tableContainer_noResultMessage'}>Future Implementation</p>
                        )}
                    </div>
                    <div id={props.id + '_bookList_viewAllContainer'}>
                        <Link id={props.id + '_bookList_viewAllContainer_link'} to={booksTabLink} onClick={setBooksTab}>
                            <span id={props.id + '_bookList_viewAllContainer_link_text'}>View All Book Results</span>
                        </Link>
                    </div>
                </ScrollPanel>
            </div>
        </div>


    )
}
export default BookListPreview