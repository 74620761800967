import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { aboutPanelRequestedAction } from './store/actions'
import { Dropdown } from 'primereact/dropdown'
import { selectAuthApiHost } from 'globalSelectors' 
import Cookies from 'js-cookie'

// ReSharper disable once InconsistentNaming
const BurgerMenu = function (props) {

    // These options can be any value, just need to be unique
    const optionAbout = "9"
    const optionLogout = "10"

    const options = [
        { label: <div>About Scribe</div>, value: optionAbout },
        { label: <div>Log Out <i className="fa fa-arrow-right"></i></div>, value: optionLogout },
        // ...other options
    ]
    const dispatch = useDispatch()
    //Signout function
    const hostUrl = useSelector(selectAuthApiHost)
    const signOutUrl = `${hostUrl}/MicrosoftIdentity/Account/SignOut/OpenIdConnect`

    const handleSignOut = () => {
        window.location.replace(signOutUrl)
        Cookies.remove('jwt')
    }

    const handleAbout = (e) => {
        dispatch(aboutPanelRequestedAction(true))
    }

    const handleMenuItemClick = (event) => {
        event.preventDefault();
        switch (event.value) {
            case optionAbout:
                handleAbout(event);
                break;            
            case optionLogout:                
                handleSignOut();
                break;            
            default:               
                break;
        }
    }
    return (
        <Dropdown            
            options={options}
            optionLabel="label"
            placeholder="Menu"
            onChange={(e) => handleMenuItemClick(e)}            
            dropdownIcon="fa fa-bars"     
            className="black ml-2 mb-4 mr-2 float-end"
            panelClassName="black"
        >
        </Dropdown>
    )
}
export default BurgerMenu