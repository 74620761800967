import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Dialog } from 'primereact/dialog';
import BookListPreview from './BookListPreview'
import AudioVisualListPreview from './AudioVisualListPreview'
import VisualArtsListPreview from './VisualArtsListPreview'
import SerialListPreview from './SerialListPreview'
import ContactListPreview from './ContactListPreview'
import OtherResults from './OtherResults'
import { searchMode as searchModes } from 'modules/enums'
import { selectEnsurePanelIsOpen, selectLatestSearchTerm, selectPanelIsVisible } from './store/selectors'
import { setPanelVisibilityAction } from './store/actions'
import { useDispatch } from 'react-redux'
import { globalSearchBarActions } from 'components/Header/GlobalSearchBar/store/actions'
// ReSharper disable once InconsistentNaming
function SearchResultsPreview(props) {
    
    const [searchTerm, setSearchTerm] = useState('')
    const dispatch = useDispatch()
    const searchMode = useSelector(state => state.components.header.searchMode)
    const ensurePanelIsOpen = useSelector(selectEnsurePanelIsOpen)
    const latestSearchTerm = useSelector(selectLatestSearchTerm)
    const searchHasLostFocus = () => dispatch(globalSearchBarActions.SetHasLostFocusAction())
    const panelIsVisible = useSelector(selectPanelIsVisible)

    if (searchTerm !== latestSearchTerm) {
        setSearchTerm(latestSearchTerm)
        if (ensurePanelIsOpen && !panelIsVisible) {
            dispatch(setPanelVisibilityAction(true))

        }
        else if (!ensurePanelIsOpen && panelIsVisible) {
            dispatch(setPanelVisibilityAction(false))

        }
    }



    const { firstSet, secondSet } = buildPanel(searchMode, { id: props.id + '_container_row' }, searchTerm)

    return (<Dialog id={props.id}
        modal={true}
        visible={panelIsVisible}
        focusOnShow={false}
        onHide={() => dispatch(setPanelVisibilityAction(false))}
        dismissableMask={true}
        style={{ width: '50vw', height:'calc(100% - 180px)', top:'-75px'}}
        header={<div>Results for: <b>{searchTerm}</b></div>}
        maskStyle={{ top: '135px', height: 'calc(100vh-135px)' }}

    >

        <div class='row'>
            <div class="col-12">
                <div class="alcs-panel">
                    {firstSet}
                    {secondSet}
                </div>
            </div>
        </div>


    </Dialog>
    )

}

const buildDefaultAndFinance = (props, searchTerm) => {
    const firstSet = (
        <div class="row">
            <div class="col-5 mb-4">
                <AudioVisualListPreview id={props.id + '_right_AvPreview'} searchTerm={searchTerm} height="370px" width="100%" setPanelIsVisible={setPanelVisibilityAction} />
            </div>
            <div class="col-4 mb-4" >
                <BookListPreview className={'row'} id={props.id + '_left_BooksPreview'} searchTerm={searchTerm} height="400px" width="100%" />
            </div>
            <div class="col-3 mb-4" >
                <ContactListPreview className={'row'} id={props.id + '_left_ContactsPreview'} searchTerm={searchTerm} height="400px" width="100%" />
            </div>
        </div>

    )
    const secondSet = (
        <div class="row">
            <div class="col-5 mb-2" >
                <SerialListPreview className={'row'} id={props.id + '_right_SerialPreview'} searchTerm={searchTerm} height="400px" width="100%" />
            </div>
            <div class="col-4 mb-2" >
                <VisualArtsListPreview id={props.id + '_right_VaPreview'} className={'row'} searchTerm={searchTerm} height="400px" width="100%" />
            </div>
            <div class="col-3 mb-2" >
                <OtherResults className={'row'} id={props.id + '_right_OtherResutlsPreview'} searchTerm={searchTerm} height="400px" width="100%" />
            </div>
        </div>
    )
    return {
        firstSet,
        secondSet
    }
}

const buildAudioVisual = (props, searchTerm) => {
    const firstSet = (
        <div class="row">
            <div class="col-8">
                <AudioVisualListPreview id={props.id + '_left_AvPreview'} searchTerm={searchTerm} height="641px" width="100%" />
            </div>

            <div class="col-4">
                <div class="mb-2">
                    <ContactListPreview id={props.id + '_right_ContactsPreview'} searchTerm={searchTerm} height="400px" width="100%" />
                </div>
                <OtherResults id={props.id + '_right_OtherResutlsPreview'} height="200px" width="100%" />
            </div>
        </div>
    )
    const secondSet = (
        <div class="row">

        </div>


    )
    return {
        firstSet,
        secondSet
    }
}

const buildMembership = (props, searchTerm) => {
    const firstSet = (
        <div class="row">
            <div class="col-6">
                <ContactListPreview className={"row"} height="641px" width="100%" />
            </div>
            <div class="col-6">
                <div class="mb-2">
                    <BookListPreview className={"row"} />
                </div>
                <AudioVisualListPreview id={props.id + '_right_AvPreview'} className={'row'} />
                <OtherResults className={"row"} />
            </div>
        </div>
    )
    const secondSet = (
        <div class="row">

        </div>
    )
    return {
        firstSet,
        secondSet
    }

}
const buildPublishedWork = (props, searchTerm) => {

    const firstSet = (
        <div class="row">
            <div class="col-6">
                <BookListPreview className={"row"} />
                <VisualArtsListPreview className={"row"} />
                <SerialListPreview className={"row"} />
            </div>
            <div class="col-6">
                <div class="mb-2">
                    <ContactListPreview className={"row"} />
                </div>
                <OtherResults className={"row"} />
            </div>     
        </div>
    )
    const secondSet = (
        <div class="row">

        </div>
    )
    return {
        firstSet,
        secondSet
    }
}

const buildPanel = (searchMode, props, searchTerm) => {

    switch (searchMode) {
        case searchModes.Default:
        case searchModes.Finance:
            return buildDefaultAndFinance(props, searchTerm)
        case searchModes.AudioVisual:
            return buildAudioVisual(props, searchTerm)
        case searchModes.Membership:
            return buildMembership(props, searchTerm)
        case searchModes.PublishedWork:
            return buildPublishedWork(props, searchTerm)
        default:
            return {
                firstSet: (<div id={props.id + '_left_container'} ></div>),
                secondSet: (<div id={props.id + '_right_container'} ></div>)

            }
    }
}

export default SearchResultsPreview
