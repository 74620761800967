import { combineReducers } from '@reduxjs/toolkit';
import { allResultsReducer } from 'components/Body/Search/SearchResultsPanels/AllResults/store'

export const searchReasultsPanelInitialState = {
    status: 'idle',
    results: {

    },
    avCurrentPage: 1,
    avVisiblePages: [],
    avTotalPages: 0,
    avRow: 100,
    avFirst: 0,
    avSort: null,
    avFilter: null
}

export const searchResultsReducer = (state = searchReasultsPanelInitialState, action) => {
    switch (action.type) {
        case 'SEARCHRESULTS_SET_FETCH_STATUS_ACTION':
            return {
                ...state,
                status: action.payload
            }
        case 'SEARCHRESULTS_SET_FETCH_RESULTS_ACTION':
            return {
                ...state,
                results: action.payload
            }
        case 'SET_PAGINATION_AV_CURRENT_PAGE':
            return {
                ...state,
                avCurrentPage: action.payload
            }       
        case 'SET_PAGINATION_AV_TOTAL_PAGES':
            return {
                ...state,
                avTotalPages: action.payload
            }
        case 'SET_PAGINATION_AV_ROWS':
            return {
                ...state,
                avRow: action.payload
            }
        case 'SET_PAGINATION_AV_FIRST':
            return {
                ...state,
                avFirst: action.payload
            }
        case 'SET_FILTER_AV_FILTER':
            return {
                ...state,
                avFilter: action.payload
            }
        case 'SET_SORT_AV_SORT':
            return {
                ...state,
                avSort: action.payload
            }
        case 'SET_AV_EXPORT_EXCEL':
            return {
                ...state,
                excelExport: {
                    searchTerm: action.payload,
                    status: 'pending'
                }
            }
        default:
            return state
    }
}

export const searchResultsPanelReducer = combineReducers({
    allResults: allResultsReducer,
    searchResults: searchResultsReducer
})