

export const allResultsInitialState = {
    audioVisual: {
        status: 'idle',
        results: {

        }
    }
}


export const allResultsReducer = (state = allResultsInitialState, action) => {
    switch (action.type) {
        case 'ALLRESULTS_AUDIOVISUAL_SET_FETCH_STATUS_ACTION':
            return {
                ...state,
                audioVisual: {
                    ...state.audioVisual,
                    status: action.payload
                }
            }
        case 'ALLRESULTS_AUDIOVISUAL_SET_FETCH_RESULTS_ACTION':
            return {
                ...state,
                audioVisual: {
                    ...state.audioVisual,
                    results: action.payload
                }
            }
        default:
            return state
    }
}