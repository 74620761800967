import { combineReducers } from '@reduxjs/toolkit'
import { globalSearchBarReducer } from '../GlobalSearchBar/store'
import { secondaryNavigationReducer } from '../SecondaryNavigation/store' 
import { HeaderSetAboutPanelRequestedAction } from './actions'

if(globalSearchBarReducer === undefined) throw new Error('globalSearchBarReducer is undefined')

const setSearchModeActionName = 'searchModeDropdown_SetSearchMode'
export const setSearchModeAction = (mode) => ({ type: setSearchModeActionName, payload: mode })

const searchModeReducer = (state = null,{type,payload}) =>
{
    switch (type) {
        case setSearchModeActionName:
            return payload
        default:
            return state
    }
}

const aboutBoxRequestedReducer = (state = false, { type, payload }) => {
    switch (type) {
        case HeaderSetAboutPanelRequestedAction:
            return payload
        default:
            return state
    }
}


export const headerReducer = combineReducers({
    globalSearchBar: globalSearchBarReducer,
    searchMode: searchModeReducer,
    secondaryNavigation: secondaryNavigationReducer,
    aboutBoxRequested: aboutBoxRequestedReducer
})