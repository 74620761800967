import { combineReducers } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'

import { headerReducer } from './Header'
import { bodyReducers } from './Body/store'

if (headerReducer === undefined) throw new Error('headerReducer is undefined')
if (bodyReducers === undefined) throw new Error('bodyReducers is undefined')

export const componentsReducer = combineReducers({
        header: headerReducer,
        body: bodyReducers
    })

export const selectComponents = (store) => store.components
export const selectAppSettings = (store) => store.appSettings
export const selectAvHost = createSelector([selectAppSettings], (appSettings) => `https://avapi.${appSettings?.dns}`)
