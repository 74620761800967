import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import  SearchResults  from './SearchResults'
import { useLocation } from 'react-router-dom'
import { extractSearchCriteria } from 'modules'
import { setBreadCrumbStack } from 'components/Header/SecondaryNavigation/store/actions'
// Placeholder to actually perform the search - then to delegate the display of the results

// ReSharper disable once InconsistentNaming
const FullSearch = (props) => {
    // This component now has a props.id and a props.className
    const [searchParams] = useSearchParams()
    const criteria = extractSearchCriteria(searchParams)
    const dispatch = useDispatch()
    const location = useLocation()

    // We need to set the breadcrumb stack 
    const breadcrumbItem = {
        label: 'SearchResults',
        path: location.pathname
    }   
    dispatch(setBreadCrumbStack([breadcrumbItem]))
    // We also need to determine which tab will be active

    const searchResultsId = `${props.id}_SearchResults`

    return (
        <SearchResults query={criteria.SearchFor} mode={criteria.SearchMode} id={searchResultsId} className={props.className} />
)
}

export default FullSearch
