import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BreadCrumb } from 'primereact/breadcrumb'
import { selectBreadCrumbItems } from 'components/Header/SecondaryNavigation/store/selectors'

// ReSharper disable once InconsistentNaming
const SecondaryNavigation = (props) => {     
    const previousLocations = useSelector(selectBreadCrumbItems)

    
    const breadHome = { label: 'Home', url: '/' }    
    const breadcrumbItems = previousLocations.map(location => ({
        label: location.label, // You might want to customize this label according to your needs
        url: location.path // You might want to customize this URL according to your needs
    }))

    return (
        <div id={props.id + '_navcontainer'} className='secondary-navigation d-flex justify-content-center' style={{ height: '50px' }}>
            <div id={props.id + '_navcontainer_2'} className='row w-100'>
                <div id={props.id + '_navcontainer_2_breadcrumb'} className='col d-flex'>
                    <div id='breadcrumb-container'>
                        <BreadCrumb model={breadcrumbItems} home={breadHome} className='breadcrumb' />
                    </div>
                </div>
                <div id={props.id + '_navcontainer_2_card'} className='col-auto d-flex flex-row justify-content-end align-items-center'>
                    <div id='type-navigation'>
                        <Link to='/AudioVisual' className='type-nav-btn' id='av-radio'>AV & Radio</Link>
                        <Link to='' className='type-nav-btn' id='books'>Books</Link>
                        <Link to='' className='type-nav-btn' id='serials'>Serials</Link>
                        <Link to='' className='type-nav-btn' id='visual-arts'>Visual Arts</Link>                        
                        <Link to='' className='type-nav-btn' id='sources'>Sources</Link>
                        <Link to='' className='type-nav-btn' id='distribution'>Distribution</Link>
                        <Link to='' className='type-nav-btn' id='income'>Income</Link>
                        <Link to='' className='type-nav-btn' id='contacts'>Contacts</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondaryNavigation
