import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Badge } from 'primereact/badge'
import { ScrollPanel } from 'primereact/scrollpanel'

// ReSharper disable once InconsistentNaming
const SearchResultsTemplate = (props) => {
    const {
        id,
        selectors,
        commonElements
    } = props
    const dispatch = useDispatch()
    const searchPreviewResults = useSelector(selectors.fetchResults)
    const selectTabLink = useSelector(selectors.tabLink)

    let resultList = []
    let searchResultsCount = 0

    if (searchPreviewResults) {
        resultList = searchPreviewResults.results
        searchResultsCount = searchPreviewResults.total
    }
    const handleViewAllClick = () => {
        dispatch(commonElements.setTab)
        dispatch(commonElements.setPanelIsVisibletoFalse)
    }

    return (
        <div class="alcs-inner-panel medium-grey">
            {commonElements.searchComponent}
            <div class="header"><span class="title">{commonElements.title}</span></div>
            <div class="content">
                {resultList && resultList.length > 0 ? (
                    <div>
                        <ScrollPanel style={{ width: commonElements.width, height: commonElements.height }}>
                            <ul className="results-list">
                                {resultList.map((item, index) => (
                                    <li>
                                        {commonElements.template(item, id + '_tableContainer_table_tableBody_row' + index, commonElements.searchTerm, commonElements.setPanelIsVisibletoFalse)}
                                    </li>
                                ))}
                            </ul>
                        </ScrollPanel>
                        <div class="footer">
                            <div class="d-flex flex-row justify-content-between align-items-center">
                                <div class="flex">
                                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500" }}>Results:</span><Badge value={searchResultsCount}></Badge>
                                </div>
                                <div class="flex">
                                    <Link to={selectTabLink} className='title-no-underline'>                                   
                                         <Button label={"View all " + commonElements.title + " Results"} icon={commonElements.viewAllIcon} onClick={handleViewAllClick} />
                                    </Link>
                                </div>  
                            </div>
                        </div>
                    </div>
                ) : (
                    <ScrollPanel id="noResultsScrollPanel" style={{ width: commonElements.width, height: commonElements.height }}>
                        <div id="noResultsDiv">
                            <p id="noResultsIcon"><i className="fa-light fa-ban"></i></p>
                            <p id="noResultsMessage1"><b>There are no {commonElements.title}</b></p>
                            <p id="noResultsMessage2"><b>matching your search query:</b></p>
                            <p id="noResultsSearchTerm"><b>{commonElements.searchTerm}</b></p>
                        </div>
                    </ScrollPanel>
                )}
            </div>
        </div>
    )
}

export default SearchResultsTemplate;
