
export default (props) => {
    return (<div id={props.id}>
        {props.children}
    </div>)
}

const hostDns = process.env.REACT_APP_DNS_HOST

if (!hostDns) {
    throw { message: 'REACT_APP_DNS_HOST was not found' }
}

const initState = {
    authApi: `https://auth.${hostDns}`,
    avApi: `https://avapi.${hostDns}`
}

export const reducer = (state = initState, action) => {
    return state
}