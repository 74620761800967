import React from 'react'
import PlusMenu from './PlusMenu'
import { Button } from 'primereact/button'

export function ActionSetLeft(props) {
    const plusMenuClass = 'col-6'
    return (
        <div id='top-cta-left' className='col-auto d-flex justify-content-center top-cta-left'>
            <PlusMenu id={props.id + '_PlusMenu'} />
            <Button label="Merge Work" icon="fa-light fa-link" className="ml-3 mb-4 col-auto" raised />
        </div>
    )
}