import { Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppSettingsAction } from './store/actions';
import { selectAllAppSettings, selectAppSettingsError } from './store/selectors';

// ReSharper disable once InconsistentNaming
const AppSettings = (props) => {

    console.log('AppSettings - props', props)

    /* Dispatch */
    var dispatch = useDispatch()
    const setAppSettings = (result) => dispatch(setAppSettingsAction(result))

    /* Selectors */
    const appSettings = useSelector(selectAllAppSettings)
    const appSettingsError = useSelector(selectAppSettingsError)

    if (!appSettings && !appSettingsError) {
        appSettingsAsync()
            .then(settings => {
                console.log('AppSettings - settings', settings)
                setAppSettings(settings)
            })
    }

    /* NB:
     * (appSettingsError && something) // if appSettingsError is "truthy" then evaluate "something"
     * (appSettingsError || something) // if appSettingsError is "falsy" then evaluate "something"
     *
     * The first child renders the error if appSettingsError is not null / undefined
     * The second child renders the loading message is appSettingsError
     *   and appSettings are null / undefined
     *
     * The third child renders the providers children if appSettingsError is null / undefined
     *   and appSettings is not null / undefined
     */

    return <div id={props.id}>
               {!!appSettingsError && <div id={props.id}>{`Unable to load appSettings: ${appSettingsError}`}</div>}
               {!!appSettingsError || !!appSettings ||
                   (<span id={props.id + '_appSettingsLoader_message'}>attempting to load app settings..</span>)}
               {!!appSettingsError || (!!appSettings && props.children)}
           </div>
}

const appSettingsAsync = async () => {
    
    const controller = new AbortController()
    const signal = controller.signal
    const timeOut = setTimeout(() => {
        controller.abort()
        return({
                error: 'Time out occurred while trying to access app settings.'
            })
    }, 5000)
    try {
        const fetchSetting = fetch('/appsettings.json', {
                signal
        })

        const response = await fetchSetting
        const settings = await response.json()

        return settings
    }
    catch (err) {
        return err
    }
    finally {
        clearTimeout(timeOut)
    }

}

export default AppSettings;
