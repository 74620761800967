import { React, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'redux-first-history'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

import SearchResultsPreview from './SearchPreview';
import { searchResultsTab, keyCodes } from 'modules/enums'
import { globalSearchBarActions } from './store/actions'
import { debounce } from 'modules/controls/debounce'
import { useNavigate } from 'react-router-dom'
import { setPanelVisibilityAction } from 'components/Header/GlobalSearchBar/SearchPreview/store/actions'
import { selectPanelIsVisible } from 'components/Header/GlobalSearchBar/SearchPreview/store/selectors'

const maxSearchLength = 250
const keyTimeout = 300 //ms
const placeholder = 'Search Scribe...'

var searchTermKeyDelay = null

// ReSharper disable once InconsistentNaming
const GlobalSearchBar = (props) => {
    const navigate = useNavigate()
    console.log('GlobalSearchBar - props', props)

    //* Local State */
    const [localTerm, setLocalTerm] = useState('')
    const [previewFocused, setPreviewFocused] = useState(false)
    const searchBoxRef = useRef('')

    //* Dispatch */
    const dispatch = useDispatch()
    const searchHasLostFocus = () => dispatch(globalSearchBarActions.SetHasLostFocusAction())
    const searchHasGainedFocus = () => dispatch(globalSearchBarActions.SetHasGainedFocusAction())
    const setSearchTerm = (term) => dispatch(globalSearchBarActions.SetSearchTermAction(term))
    const setPanelInvisible = () => dispatch(setPanelVisibilityAction(false))
    const setPanelVisible = () => dispatch(setPanelVisibilityAction(true))

    //* Selectors */
    const panelIsVisible = useSelector(selectPanelIsVisible)

    //* Delayed Search Term Update */
    if (!searchTermKeyDelay) {
        searchTermKeyDelay = debounce(keyTimeout, (term, setPending) => {
            setSearchTerm(term)

        })
    }

    if (panelIsVisible) {
        searchHasGainedFocus()
    }

    const handleSearchInputChange = (event) => {
        const newSearchTerm = event.target.value
        setLocalTerm(newSearchTerm)

        if (newSearchTerm.length >= 3) {
            searchTermKeyDelay(newSearchTerm, true)
            if (!panelIsVisible) {
                setPanelVisible()
            }            
        }
        else {
            if (panelIsVisible) {
                setPanelInvisible()
            }
        }
        
    }


    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
        searchHasLostFocus()
    }
    //// Fired when the user decides to execute the search
    const onSearch = () => {
        console.log('search clicked')
        if (localTerm.length >= 3) {
            setPreviewFocused(false)
            setPanelInvisible()
        }
        navigateTo('/search')
    }

    const onKeyUp = (e) => {
        if (e.keyCode === keyCodes.Enter) {
            onSearch()
        }
    }
    const onBlur = (e) => {
        // If the focus is lost to an element inside the preview panel, refocus the input
        const currentTarget = e.currentTarget;
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement) && panelIsVisible) {
                searchBoxRef.current.focus()
            }
        }, 0)
    }

    const onFocus = () => {
        searchHasGainedFocus()
        setPreviewFocused(true)
    }

    return (
        <div id='search-container' className='col ml-9 d-flex justify-content-center search-container'>
            <span id={props.id} className={'col-8 p-inputgroup'}>
                <InputText id={props.id + '_searchBox'}
                    placeholder={placeholder}
                    maxLength={maxSearchLength}
                    value={localTerm}
                    ref={searchBoxRef}
                    onKeyUpCapture={onKeyUp}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={handleSearchInputChange}
                     />
                <Button icon="fa-light fa-magnifying-glass" aria-label="Search" onClick={onSearch} />
                <SearchResultsPreview id={props.id + '_preview'} className={'preview_panel'} isVisible={searchHasGainedFocus}  />
            </span>
        </div>
    )
}

export default GlobalSearchBar
