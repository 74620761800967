import { scribeUrlType } from 'modules/enums'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { urlBuilder } from 'modules'
import bookimage from 'assets/images/Book.png'
import vaImage from 'assets/images/VisualArts.jpg'
import lockimage from 'assets/images/Lock.png'
import HighlightText from 'components/common/HighlightText'
import { convertMinutesToDuration } from 'modules'

export const avTemplate = (item, parentId, searchTerm, setPanelIsVisibletoFalse) => {
    return (

        <div class="d-flex flex-row align-items-top">
            <div class="d-flex flex-wrap flex-column">
                <div class="info d-flex flex-wrap mt-2">
                    <div class="d-flex align-items-center mr-2"><i className={item.iconId === 1 ? "fa-light fa-tower-cell" : item.iconId === 2 ? "fa-light fa-film" : item.iconId === 3 ? "fa-light fa-tv-retro" : "fa-light fa-question"
                    } style={{ fontSize: "15px" }}></i></div>
                    <div class="title-link"><Link className='title-no-underline' onClick={setPanelIsVisibletoFalse} to={
                        item.iconId === 2
                            ? `${urlBuilder(scribeUrlType.AudioVisual, item.programmeId)}/${item.episodeId}`
                            : urlBuilder(scribeUrlType.AudioVisual, item.programmeId)
                    }> Prog: <HighlightText text={item.programmeTitle} searchTerm={searchTerm} /><i class="pi pi-angle-right"> </i></Link></div>
                    <div class="title-link"><Link className='title-no-underline' onClick={setPanelIsVisibletoFalse} to={
                        `${urlBuilder(scribeUrlType.AudioVisual, item.programmeId)}/${item.episodeId}`
                    }>Ep: <HighlightText text={item.episodeTitle} searchTerm={searchTerm} /><i class="pi pi-angle-right"> </i></Link></div>
                    {item.iconId != 2 && (
                        <div class="d-flex mr-2"><span class="label">Series:</span><span class="data">{item.episodeSeries}</span></div>
                    )}
                    <div class="d-flex mr-2"><span class="label">Duration:</span><span class="data">{convertMinutesToDuration(item.episodeDuration)}</span></div>
                    <div class="d-flex mr-2"><span class="label">Date:</span><span class="data">{item.episodeYear}</span></div>
                    {item.iconId === 2 && (
                        <div class="d-flex mr-2"><span class="label">IDA:</span><span class="data"><a href="">{item.episodeIda}</a></span></div>
                    )}                    
                </div>
            </div>
        </div>
    )
}
//Todo: Series, Duration, IDA,  and anything to do with images was not in the audiovisual fulltext search spec. This'll have to be reviewed later.

export const booksTemplate = (item, parentId) => {
    return (
        <div id={parentId + '_rContainer'}>
            <p id={parentId + '_rContainer_para'} style={{ display: 'inline-block' }}>
                <img id={parentId + '_rContainer_para_image'} className="w-1rem shadow-2 flex-shrink-0 border-round" src={bookimage} alt={item.Title} width="25" height="25" />
                &nbsp;
                <Link id={parentId + '_rContainer_para_link'} to={urlBuilder(scribeUrlType.PublishedWork, item.Id)}>
                    <button id={parentId + '_rContainer_para_link_button'} className="Preview-Content-Font">{item.Title}</button>
                </Link>
            </p>
            <span id={parentId + '_rContainer_span1'}>
                &nbsp; {item.year}
            </span>
            <br />
            <span id={parentId + '_rContainer_span2'}>
                <b id={parentId + '_rContainer_span2_b1'}>Author</b>
                &nbsp;
                <Link id={parentId + '_rContainer_span2_ahref'} to={urlBuilder(scribeUrlType.Contact, item.AuthorId)}><u>{item.Author}</u></Link>
                &nbsp;
                <button id={parentId + '_rContainer_span2_button'} class="search-result-preview-count">{item.Points}</button>
                &nbsp;
                <b id={parentId + '_rContainer_span2_b2'}>Author Key</b>
                &nbsp;
                <Link id={parentId + '_rContainer_span2_ahref'} to={urlBuilder(scribeUrlType.Contact, item.AuthorId)}><u id={parentId + '_rContainer_span2_ahref_utext'} font-size="0.875em">{item.Authorkey}</u></Link>
                &nbsp;
                <b id={parentId + '_rContainer_span2_b3'}>ISBN</b>
                &nbsp;
                {item.ISBN}
            </span>
        </div>
    )
}

export const serialTemplate = (item, parentId) => {
    return (
        <div id={parentId + '_rContainer'} >
            <p id={parentId + '_rContainer_para'} style={{ display: 'inline-block' }}>
                <img id={parentId + '_rContainer_para_image'} className="w-1rem shadow-2 flex-shrink-0 border-round" src={vaImage} alt={item.Title} width="25" height="25" />
                &nbsp;
                <Link id={parentId + '_rContainer_para_link'} to={urlBuilder(scribeUrlType.Serial, item.ISSN)}>
                    <b id={parentId + '_rContainer_para_link_b'} >{item.Title}</b>
                </Link>
                &nbsp; {item.year}
            </p>
            <br />
            <span id={parentId + '_rContainer_span1'}>
                <b id={parentId + '_rContainer_span_b1'}>IS SN</b>
                &nbsp;
                {item.ISSN}
                &nbsp;
                <b id={parentId + '_rContainer_span_b2'}>Word Count</b>
                &nbsp;
                {item.WordCount}
                &nbsp;
                <b id={parentId + '_rContainer_span_b3'}>Claims</b>
                &nbsp;
                {item.Claims}
                &nbsp;
                <b id={parentId + '_rContainer_span_b4'}>Work Code</b>
                &nbsp;
                {item.WorkCode}
            </span>
            <br />
            <span id={parentId + '_rContainer_span2'}>
                <b id={parentId + '_rContainer_span2_b1'}>Article</b>
                &nbsp;
                {item.Title}
                &nbsp;
                <b id={parentId + '_rContainer_span2_b2'}>Author Key</b>
                &nbsp;
                <Link id={parentId + '_rContainer_span2_href'} to={urlBuilder(scribeUrlType.Contact, item.WorkCode)}><u id={parentId + '_rContainer_span2_href_utext'}>{item.WorkCode}</u></Link>
                &nbsp;
                <button id={parentId + '_rContainer_span2_button'} class="search-result-preview-count">{item.Points}</button></span>
        </div>
    )
}

export const visualArtTemplate = (item, parentId) => {
    return (
        <div id={parentId + '_rContainer'}>
            <p id={parentId + '_rContainer_para'} style={{ display: 'inline-block' }}>
                <img id={parentId + '_rContainer_para_image'} src={vaImage} alt={item.Title} width="25" height="25" />
                &nbsp;
                <Link id={parentId + '_rContainer_link'} to="/books/Book1">
                    <b id={parentId + '_rContainer_link_b'} className="Preview-Content-Font">{item.Title}</b>
                </Link>
            </p>
            <br />
            <span id={parentId + '_rContainer_span1'}><b id={parentId + '_rContainer_span1_b1'}>Author Key</b> &nbsp;{item.AuthorKey}  &nbsp; <b id={parentId + '_rContainer_span1_b2'}>Claims</b>  &nbsp;{item.Claims}  &nbsp; <b id={parentId + '_rContainer_span1_b3'}>VA Code</b> &nbsp; {item.VACode} &nbsp; <b id={parentId + '_rContainer_span1_b4'}>Qty</b> &nbsp; {item.Quantity}</span>
            <br />
            <span id={parentId + '_rContainer_span2'}><b id={parentId + '_rContainer_span2_b'}>ISBN</b> &nbsp;{item.ISBN}</span>
        </div>
    )
}

export const contactTemplate = (item, chevronAction, displayAction, parentId) => {
    return (
        <div id={parentId + '_rContainer'}>
            <p id={parentId + '_rContainer_para'} style={{ display: 'inline-block' }} >
                <span id={parentId + '_rContainer_para_span'} className="pi pi-user"></span>
                &nbsp;
                <b id={parentId + '_rContainer_para_b1'}>{item.Name}</b>
                &nbsp;
                <Link id={parentId + '_rContainer_para_href'} to={urlBuilder(scribeUrlType.Contact, item.Ref)}><b id={parentId + '_rContainer_para_href_b'}>{item.LastName}</b></Link>
                &nbsp;
                <button id={parentId + '_rContainer_para_button1'}>SE16FE</button>
                &nbsp;
                <button id={parentId + '_rContainer_para_button2'}>United Kingdom</button>
                &nbsp;
                <button id={parentId + '_rContainer_para_button3'} class="search-result-preview-button">ORDINARY</button>
                &nbsp;
                <img id={parentId + '_rContainer_para_image'} className="w-1rem shadow-2 flex-shrink-0 border-round" src={lockimage} alt={item.Title} width="20" height="20" />
            </p>
            <br />
            <span id={parentId + '_rContainer_span'}>
                <p id={parentId + '_rContainer_span_para'} className="text-sm">
                    <b id={parentId + '_rContainer_span_para_b1'}>ALCS Ref</b>
                    &nbsp;
                    <Link id={parentId + '_rContainer_span_para_href'} to={urlBuilder(scribeUrlType.Contact, item.Ref)}>{item.Ref}</Link>
                    &nbsp;
                    <b id={parentId + '_rContainer_span_b2'}>DoB</b>
                    &nbsp;
                    {item.DOB}
                    &nbsp;
                    <b id={parentId + '_rContainer_span_para_b3'}>Paid</b>
                    &nbsp;
                    Yes
                    &nbsp;
                    <b id={parentId + '_rContainer_span_para_b4'}>Start Date</b>
                    &nbsp;
                    {item.StartDate}
                    &nbsp;
                    {chevronAction(item)}
                </p>
            </span>
            {displayAction(item)}

        </div>
    )
}

export const highEarningWorkTemplate = (item, parentId) => {
    return (
        <div id={parentId + '_Container'}>
            High Earning Work
            <p id={parentId + '_Container_para'} style={{ display: 'inline-block' }}>
                <table id={parentId + '_Container_para_tbl'}>
                    <tr id={parentId + '_Container_para_tbl_row'}>
                        <td id={parentId + '_Container_para_tbl_row_data1'} >
                            <img id={parentId + '_Container_para_tbl_row_data_img'} className="w-1rem shadow-2 flex-shrink-0 border-round" src={bookimage} alt={item.Title} height="50" />
                        </td>
                        <td id={parentId + '_Container_para_tbl_row_data2'}>
                            <Link id={parentId + '_Container_para_tbl_row_data2_link'} to="/books/Book1">
                                <b id={parentId + '_Container_para_tbl_row_data2_link_b'} className="Preview-Content-Font">{item.Title}</b>
                            </Link>
                            &nbsp; {item.year} &nbsp;
                            <Button id={item.Ref} icon="pi pi-angle-right" size="small" text />
                            <br />
                            <b id={parentId + '_Container_para_tbl_row_data2_b1'}>ISBN</b> &nbsp; {item.ISBN}
                            <br />
                            <b id={parentId + '_Container_para_tbl_row_data2_b2'}>Type</b> &nbsp; Book
                        </td>
                    </tr>
                </table>
            </p>
            <br />
        </div>
    )
}