
/** Global Search Bar*/
const searchPrefix = '_globalSearchBar_'
export const actionNames = {
        SetSearchTermActionName: searchPrefix + 'SET_SEARCH_TERM',
        SetHasLostFocusActionName: searchPrefix + 'SET_HAS_LOST_FOCUS',
        SetHasGainedFocusActionName: searchPrefix + 'SET_HAS_GAINED_FOCUS'
    }

export const globalSearchBarActions = {
        SetSearchTermAction: (term) => ({
                type: actionNames.SetSearchTermActionName,
                payload: term
            }),
        SetHasLostFocusAction: () => ({
                type: actionNames.SetHasLostFocusActionName,
                payload: false
            }),
        SetHasGainedFocusAction: () => ({
                type: actionNames.SetHasGainedFocusActionName,
                payload: true
            })
    }

/** Previews*/
const prefix = '_preview_'

/** Audio Visual */
const audioVisualPrefix = prefix + '_AudioVisual_'

/** Book */
const bookPrefix = prefix + '_Book_'

/** Contact */
const contactPrefix = prefix + '_Contact_'

/** Serial */
const serialPrefix = prefix + '_Serial_'

/** Visual Art */
const visualArtPrefix = prefix + '_VisualArt_'
