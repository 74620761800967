import { React, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ScrollPanel } from 'primereact/scrollpanel'
import { Link } from 'react-router-dom'
import 'primeicons/primeicons.css'
import { DataView } from 'primereact/dataview'
import { Button } from 'primereact/button'
import bookData from 'api/FullTextSearch/Books/data.json' //to display Highest Earning Work
import { setSearchResultsTabSelect } from 'components/Body/Search/store/actions'
import { contactTemplate, highEarningWorkTemplate } from 'components/common/PreviewTemplate'
import { searchResultsTab } from 'modules/enums'
import { selectContactsTabLink } from './store/selectors'

// ReSharper disable once InconsistentNaming

function ContactListPreview(props) {
    /* Dispatch */
    const dispatch = useDispatch()
    const setContactsTab = () => dispatch(setSearchResultsTabSelect(searchResultsTab.Contacts))


    /* Selectors */
    const contactsTabLink = useSelector(selectContactsTabLink)

    let contactResultList = []
    let contactSearchResultsCount = 0

    const showChevronIcon = (item) => {
        return (
            <b>
                {!item.showEarningFlag && <Button id={item.Ref} icon="pi pi-angle-down" onClick={openHighEarningWork} text style={{ width: '10px', height: '10px', marginTop: '1px', fontSize: '1em' }} />}
                {item.showEarningFlag && <Button id={item.Ref} icon="pi pi-angle-down" onClick={closeHighEarningWork} text style={{ width: '10px', height: '10px', marginTop: '1px', fontSize: '1em' }} />}
            </b>
        )
    }
    const showHighEarningPreview = (item) => {
        return (
            <b>
                {item.showEarningFlag && (<div><DataView value={highEarningWork} itemTemplate={highEarningWorkTemplate} /> <br /></div>)}
            </b>
        )
    }


    const highEarningWork = bookData.slice(0, 1)
    const openHighEarningWork = function (e) {
        props.chevronClick()
    }
    const closeHighEarningWork = function (e) {
        props.chevronClick()
    }

    return (
        <div class="alcs-inner-panel medium-grey">
            <div class="header"><span class="title">Contacts</span></div>
            <div class="content">

                <ScrollPanel id={props.id + '_scrollPanel'} style={{ width: props.width, height: props.height }}>
                    <div id={props.id + '_contactList'}>
                        <b id={props.id + '_contactList_title'}>Contacts
                            <button id={props.id + '_contactList_title_button'}>{contactSearchResultsCount} results</button>
                        </b>
                        <br />
                        <div id={props.id + '_contactList_tableContainer'} className={'table-responsive'}>
                            {contactResultList && contactResultList.length > 0 ? (
                                <table id={props.id + '_contactList_tableContainer_table'} class="table">
                                    <thead id={props.id + '_contactList_tableContainer_table_head'}>
                                        {/* <!-- Table header goes here -->*/}
                                    </thead>
                                    <tbody id={props.id + '_contactList_tableContainer_table_body'}>
                                        {/*<!-- Use a loop to iterate through contactResultList and generate rows with contactTemplate -->*/}
                                        {contactResultList.map((item, index) => (
                                            <tr id={props.id + '_contactList_tableContainer_table_body_row_' + index}>
                                                {/* <!-- Render data using contactTemplate -->*/}
                                                {contactTemplate(item, showChevronIcon, showHighEarningPreview, props.id + '_contactList_tableContainer_table_body_row_' + index)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p id={props.id + '_contactList_tableContainer_noResultMessage'}>Future Implementation</p>
                            )}
                        </div>
                        <div id={props.id + '_contactList_viewAllContainer'}>
                            <Link id={props.id + '_contactList_viewAllContainer_link'} to={contactsTabLink} onClick={setContactsTab}>
                                <span id={props.id + '_contactList_viewAllContainer_link_text'}>View All Contacts Results</span>
                            </Link>
                        </div>

                    </div>
                </ScrollPanel>
            </div>
        </div>

    )
}
export default ContactListPreview