export async function authFetch(url, options) {
    const token = sessionStorage.getItem('authToken');
    const tokenData = JSON.parse(token);
    const bearerToken = tokenData.EncryptedToken;
    const headers = options && options.headers ? options.headers : {};
    return await fetch(url, {
        ...options,
        headers: {
            ...headers,
            Authorization: `Bearer ${bearerToken}`
        }
    })
}

export default authFetch;
