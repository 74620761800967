
export const handlePrimaryCountryChange = (e, isPrimary, primaryCountry, secondaryCountries, setAcceptedCountry, clearAcceptedCountry) => {
   
    const selectedPrimaryCountry = e.value?.id
    const previousPrimaryCountry = primaryCountry?.countryId

    if (selectedPrimaryCountry !== previousPrimaryCountry) {
        if (secondaryCountries.some(country => country.countryId === selectedPrimaryCountry)) {
            setAcceptedCountry({ [previousPrimaryCountry]: { countryId: previousPrimaryCountry, isPrimary: false } })
        } else {
            clearAcceptedCountry(previousPrimaryCountry)
        }
        setAcceptedCountry({ [selectedPrimaryCountry]: { countryId: selectedPrimaryCountry, isPrimary: isPrimary } })
    }
}

export const handleSecondaryCountryChange = (e, secondaryCountries,setAcceptedCountry, clearAcceptedCountry) => {
 
    const selectedCountries = e.value
    const previousCountries = secondaryCountries.map(country => country.countryId)

    // Check if a country was added
    const addedCountries = selectedCountries.filter(country => !previousCountries.includes(country))
    if (addedCountries.length > 0) {
        const countriesToAdd = addedCountries.map(country => ({ countryId: country, isPrimary: false }))
        const countryDictionary = countriesToAdd.reduce((acc, country) => {
            acc[country.countryId] = country
            return acc
        }, {})
        setAcceptedCountry(countryDictionary)
    }

    // Check if a country was deleted
    const deletedCountries = previousCountries.filter(countryId => !selectedCountries.includes(countryId))
    if (deletedCountries.length > 0) {
        clearAcceptedCountry(deletedCountries)
    }
}

export const handleProductionCompaniesChange = (e, productionCompaniesDraft, setAcceptedProductionCompanies, clearProgrammeDraftResultsProductionCompanies) => {
    const selectedCompanies = e.value
    const previousCompanies = productionCompaniesDraft.map(company => company.companyId)
    const addedCompanies = selectedCompanies.filter(company => !previousCompanies.includes(company))
    if (addedCompanies.length > 0) {
        const companiesToAdd = addedCompanies.map(company => ({ companyId: company }))
        const companyDictionary = companiesToAdd.reduce((acc, company) => {
            acc[company.companyId] = company
            return acc
        }, {})
        setAcceptedProductionCompanies(companyDictionary)
    }
    const deletedCompanies = previousCompanies.filter(companyId => !selectedCompanies.includes(companyId))
    if (deletedCompanies.length > 0) {
        clearProgrammeDraftResultsProductionCompanies(deletedCompanies)
    }
}

//get name of Production company Id from asset

export const getCompanyNameById = (productionCompanies,companyId) => {
    const company = productionCompanies.find(pc => pc.id === companyId)
    return company ? { companyId: company.id, company: company.name } : null
}

export const removeDuplicatesTitles = (array) => {
    const seen = new Set()
    return array.filter(item => {
        if (seen.has(item.title)) {
            return false
        } else {
            seen.add(item.title);
            return true
        }
    })
}

export const removeEmptyRowsFromTitle = (array) => {
    return array.filter(title => {
        if (!title.title && !title.languageId) {
            return false
        }
        return true
    })
}