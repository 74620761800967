import React from 'react'
import Version from './Version'
import SignalRIndicator from './SignalRIndicator'

const versionClassName = 'col-2 float-end'
const iconClassName = 'col-2'

// ReSharper disable once InconsistentNaming
const Footer = (props) => {

    return (
        <div id={'footer'} className={props.className + ' row'}>
            <SignalRIndicator id={'footer' + '_signalRIndicator'} className={iconClassName}/>
        </div>
    )
}

export default Footer
