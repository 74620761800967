const initState = {
        updatedAggregates: [],
        connectionId: null,
        status:'Idle'
    }

export const signalRReducer = function(state = initState, {
                                           type,
                                           payload
                                       }) {
    switch (type) {
        case 'SignalR_AggregateUpdated':
            var updatedAggregates = state.updatedAggregates
            updatedAggregates.slice(Math.max(updatedAggregates.length - 199, 0))
            updatedAggregates.push(payload)
            return {
                    ...state,
                    updatedAggregates
                }
        case 'SignalR_connected':
            console.log(`connectionId: ${payload}`)
            return {
                    ...state,
                    connectionId: payload
                }
        case 'SignalR_ConnectionError':
            return {
                    ...state,
                    connectionError: payload
                }
        case 'SignalR_StatusUpdate':
            return {
                    ...state,
                    status: payload
                }
        default:
            return state
    }
}
