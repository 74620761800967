import { createSelector } from 'reselect';
import { urlBuilder } from 'modules'
import { selectGlobalSearchBar, selectGlobalSearchBarAppSettings, selectSearchMode } from '../../store/selectors'
import { scribeUrlType, searchMode } from 'modules/enums'
import { selectAvApiHost } from 'globalSelectors'


const selectSearchPreview = state =>             selectGlobalSearchBar(state).searchPreview
export const selectAudioVisual = state =>        selectSearchPreview(state).audioVisual
export const selectPanelIsVisible = state =>     selectSearchPreview(state).panelIsVisible
export const selectSearchTerm = state =>         selectGlobalSearchBar(state).searchTerm
const selectHasFocus = state =>                  selectGlobalSearchBar(state).searchHasFocus
const selectSearchPreviewAppSettings = state =>  selectGlobalSearchBarAppSettings(state).SearchPreview
const selectResultCounts = state =>              selectSearchPreviewAppSettings(state).ResultCounts

const searchUrlBuilder = (searchTerm, searchMode) => urlBuilder(scribeUrlType.Search, searchTerm, searchMode)

const fetchRequest = (store, count,page, term, url) => ({ count,page, term, url, status: store.status })

/* Panel */

export const selectEnsurePanelIsOpen = createSelector(
        [selectSearchTerm, selectHasFocus],
        (term, hasFocus) => hasFocus && term.length >= 3
)
export const selectLatestSearchTerm = createSelector(
    [selectSearchTerm],
    (term) => term
  )

/*AudioVisual */
export const selectAvTabLink = createSelector(
        [selectSearchTerm],
        searchTerm => searchUrlBuilder(searchTerm, searchMode.TvFilmRadio)
    )
export const selectTvFilmRadioUrl = createSelector(
        [selectAvApiHost],
        host => `${host}/search`
)
export const selectTvFilmRadioResultsCount = createSelector(
        [selectResultCounts, selectSearchMode],
        (counts, mode) => counts[mode].TvFilmRadio
)
export const selectAudioVisualResultsPage = createSelector(
    [],
    () => 1
)
export const selectTvFilmRadioFetchRequest = createSelector(
    [selectAudioVisual, selectTvFilmRadioResultsCount, selectAudioVisualResultsPage, selectSearchTerm, selectTvFilmRadioUrl],
    fetchRequest
)
export const selectTvFilmRadioFetchResults = createSelector(
    [selectAudioVisual],
    (av)=>av.results
)

/*Books*/

export const selectBooksTabLink = createSelector(
        [selectSearchTerm],
        searchTerm => searchUrlBuilder(searchTerm, searchMode.PublishedWork)
)


/*Contacts */
export const selectContactsTabLink = createSelector(
    [selectSearchTerm],
    searchTerm => searchUrlBuilder(searchTerm, searchMode.Contact)
)

/*Serials */
export const selectSerialsTabLink = createSelector(
    [selectSearchTerm],
    searchTerm => searchUrlBuilder(searchTerm, searchMode.Serial)
)

/*VisualArt */
export const selectVisualArtsTabLink = createSelector(
    [selectSearchTerm],
    searchTerm => searchUrlBuilder(searchTerm, searchMode.VisualArt)
)


export const selectAvFetchIsPending = createSelector(
    [selectAudioVisual],
    (av) => (av.status === "pending")
)