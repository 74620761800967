import React,{ forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { push } from 'redux-first-history'
import { confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import { validateAndSetDefaultEpisodeDetails } from 'components/Body/data-entry/AudioVisual/validations'
import { selectEpisodeDraftHasDeletedTransmissions, selectEpisodeResultHasChanges } from 'components/Body/data-entry/AudioVisual/store/selectors' 
import { setEpisodeDataUpdateKeyAction,setShowEpisodeDataUpdateAction,setShowEpisodeDataCreateAction, setEpisodeGuidAction, clearEpisodeResultsTransmissionsFromDraftsAction, setEpisodeDraftResultsTransmissionsAction, setEpisodeUpdateStatusAction, clearProgrammeDraftResultsEpisodesAction } from 'components/Body/data-entry/AudioVisual/store/actions'

// ReSharper disable once InconsistentNaming
export const EpisodeDetailsFooter = forwardRef(({ cancelConfirmRef, episodeData, programmeData, episodePrimaryTitle, toast, isNewEpisode }) => {
    const dispatch = useDispatch()

    const draftAvailable = useSelector(selectEpisodeResultHasChanges)  
    const deleteTransmissionAvailable = useSelector(selectEpisodeDraftHasDeletedTransmissions)

    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const clearEpisodeDraftResultsTransmissions = () => dispatch(clearEpisodeResultsTransmissionsFromDraftsAction())
    const setEpisodeDraftResultsTransmissions = (transmissions) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmissions))
    const setShowDataUpdate = (show) => dispatch(setShowEpisodeDataUpdateAction(show))
    const setShowDataCreate = (show) => dispatch(setShowEpisodeDataCreateAction(show))
    const setDataUpdateKey = (key) => dispatch(setEpisodeDataUpdateKeyAction(key))

    const handleCancelButtonClick = () => {
        dispatch(setEpisodeGuidAction(null))
        const currentUrl = window.location.href
        const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'))
        dispatch(push(baseUrl))
    }
    const handleCreateTrigger = () => {
        dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
        setShowDataCreate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataCreate(true)
    }

    const handleUpdateTrigger = () => {       
        dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
        setShowDataUpdate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataUpdate(true)       
    }
 
    /**** Cancel Logic in Footer - Begin ****/
    const accept = () => {
        if (!draftAvailable) {
            handleCancelButtonClick()
        }
        try {
            clearProgrammeEpisodeDraftResults()

            toast.current.show({
                severity: 'info',
                summary: 'Confirmed',
                detail: 'Pending changes are cancelled',
                life: 3000
            })
            handleCancelButtonClick()
        } catch (error) {
            console.error('Error in accept function:', error)
        }
    }

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to discard them?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            })
        }
        else {
            handleCancelButtonClick()
        }
    }

    useImperativeHandle(cancelConfirmRef, () => ({
        showCancelConfirm: (event) => showCancelConfirm(event)
    }))
    /**** Cancel Logic in Footer - End ****/

    /**** Save Logic in Footer - Begin ****/   

    //On direct click of Accept in split button
    const splitSave = (event) => {
        if (!validateAndSetDefaultEpisodeDetails(toast,dispatch,episodeData, programmeData, episodePrimaryTitle)) {
            return
        }
        if (isNewEpisode) {
            handleCreateTrigger()
        }
        else {
            handleUpdateTrigger()
        }
    }

    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Save',
            icon: 'fa-light fa-check',
            command: (event) => {
                if (deleteTransmissionAvailable) {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'You are about to delete Transmissions. Do you want to continue?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: acceptSave,
                        reject: rejectTransmissionChange
                    })
                }
                else {
                    if (!validateAndSetDefaultEpisodeDetails(toast, dispatch, episodeData, programmeData, episodePrimaryTitle)) {
                        return
                    }
                    if (isNewEpisode) {
                        handleCreateTrigger()
                    }
                    else {
                        handleUpdateTrigger()
                    }
                }

            }
        },
        {
            label: 'Save and Exit',
            icon: 'fa-light fa-check',
            command: () => {
                if (!validateAndSetDefaultEpisodeDetails(toast, dispatch, episodeData, programmeData, episodePrimaryTitle)) {
                    return
                }
                if (isNewEpisode) {
                    handleCreateTrigger()
                    setTimeout(() => {
                        handleCancelButtonClick()
                        window.location.reload()
                    }, 1000);
                }
                else {
                    handleUpdateTrigger()
                    setTimeout(() => {
                        handleCancelButtonClick()
                        window.location.reload()
                    }, 1000);
                }
                

            }
        }
    ]

    const acceptSave = () => {
        if (!validateAndSetDefaultEpisodeDetails(toast, dispatch, episodeData, programmeData, episodePrimaryTitle)) {
            return
        }
        handleUpdateTrigger()
        setTimeout(() => {
            handleCancelButtonClick()
            window.location.reload()
        }, 1000);
                 
    }

    const rejectTransmissionChange = () => {
        clearEpisodeDraftResultsTransmissions()
        dispatch(setEpisodeDraftResultsTransmissions(episodeData?.transmissions))
    }

   
    /**** Save Logic in Footer - End ****/

    return (       
        <div className="app-footer episode-details-footer">
            <SplitButton className='green mr-2 mt-2' label="Save" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} ></Button>
        </div>
    )
})