import { scribeUrlType, searchMode } from 'modules/enums'

/**************************************************************************************
 * Note that the hardwired paths in this function must tally with those in
 * React Router (approutes.js). DO NOt CHANGE.
 **************************************************************************************/

export function urlBuilder(urlType, qualifier1, qualifier2 = searchMode.Default) {

    // qualifier1 is usually an ID of some sort. We don't get precious about its type, we just echo it.
    // However, when we are searching, qualifier1 is expected to be the search string.

    // qualifier2 is usually unused.
    // However when we are searching, we use its value to set the SearchMode attribute.
    // As it happens, SearchMode will be used in the Full Search component, to decide which tab to display by default.
    

    switch (urlType) {
        case scribeUrlType.AudioVisual:
            return `/AudioVisual/${qualifier1}`
        case scribeUrlType.Contact:
            return `/Contact/${qualifier1}`
        case scribeUrlType.PublishedWork:
            return `/PublishedWork/${qualifier1}`
        case scribeUrlType.Serial:
            return `/Serial/${qualifier1}`
        case scribeUrlType.VisualArt:
            return `/VisualArt/${qualifier1}`
        case scribeUrlType.Search:
            return encodeURI(`/search?Mode=${qualifier2}&For=${qualifier1}`)
        default:
            return '/'
    }
}

export function extractSearchCriteria(queryString) {
    
    return {
        SearchFor: decodeURI(queryString.get('For')),
        SearchMode: queryString.get('Mode')
    }

}
